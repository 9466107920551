import React, { useEffect, useState } from "react";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { REACT_APP_API_URL } from "../../index";

const PopularList = () => {
  const photosForMainPopular = useSelector(
    (state) => state.products.photosForMainPopular
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [startIndex, setStartIndex] = useState(0);

  const images = [];
  photosForMainPopular.map((image) => {
    images.push({
      image_path: `${REACT_APP_API_URL}product_images/${image.image_path}`,
      season_category: image.season_category,
    });
  });

  const totalImages = images.length;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const numVisibleThumbnails = windowWidth > 575 ? 2 : 1;

  const generateThumbnailIndexes = () => {
    const indexes = [];
    for (let i = 0; i < numVisibleThumbnails; i++) {
      const index = (startIndex + i) % totalImages;
      indexes.push(index);
    }
    return indexes;
  };

  return (
    <div className="s-line s-moreproducts-slider pb-40">
      {images.length > 0 && (
        <div className="container">
          <div className="w-more-products-slider">
            <div className="section-name _h3 uppercase bold align-center mb-10">
              Популярные категории
            </div>
            <div className="mb-10 align-center">ваши любимые модели</div>
            <div className="owl-nav">
              <div className="mb-10 align-center"></div>
              <div
                className="dots-container align-center"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div
                  className="owl-dot slider-prev "
                  onClick={() => {
                    let newStartIndex = startIndex - 1;
                    if (newStartIndex === -1) {
                      newStartIndex = totalImages - 1;
                    }
                    setStartIndex(newStartIndex);
                  }}
                >
                  {" "}
                  <HiArrowLongLeft
                    style={{ fontSize: "30px", marginRight: "12px" }}
                  />
                </div>
                <div
                  className="owl-dot slider-next"
                  onClick={() => {
                    setStartIndex((startIndex + 1) % totalImages);
                  }}
                >
                  <HiArrowLongRight
                    style={{ fontSize: "30px", marginLeft: "12px" }}
                  />
                </div>
              </div>
            </div>

            <div
              className="owl-carousel owl-popular-cat arrows-on-top owl-loaded owl-drag"
              data-owl-carousel='{"items":2,"nav":true,"loop":true,"responsive":{"0":{"margin":8,"items":1},"575":{"margin":30,"items":2},"765":{"margin":30,"items":2},"992":{"margin":20,"items":2},"1900":{"margin":15,"items":2}}}'
            >
              <div className="owl-stage-outer" style={{ paddingTop: 0 }}>
                <div
                  className="owl-stage"
                  style={{
                    transition: "all 0.8s ease 0s",
                    width: "3920px",
                  }}
                >
                  {generateThumbnailIndexes().map((product) => (
                    <div
                      className="owl-item active"
                      style={{
                        width: windowWidth > 575 ? "540px" : "345.2px",
                        marginRight: windowWidth > 575 ? "20px" : "8px",
                      }}
                    >
                      <div className="slide">
                        <a
                          href={`/catalog?season=${photosForMainPopular[product].season_category.id}`}
                        >
                          <img src={images[product].image_path} alt="" />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopularList;
