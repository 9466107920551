import React from "react";
import MenuBar from "../../components/information/menuBar";

import "../../components/information/informations.scss";
import Title from "../../components/common/title";

const Confidential = () => {
  return (
    <div className={"information"}>
      <Title str={"Политика конфиденциальности"} />
      <div className={"product_info"}>
        <div style={{ padding: "0 15px 40px" }}>
          <MenuBar />
        </div>
        <div className={"product_info_description"}>
          <div className={"zx-6"}>
            <h1 className={"title"}>Политика конфиденциальности</h1>
            <article className={"bigger"}>
              <p className="MsoNormal">
                Настоящая политика информирует пользователей ресурса{" "}
                <span lang="EN-US" className={"zx-9"}>
                  Marselshoes
                </span>{" "}
                о правилах конфиденциальности, применяемых на ресурсе
                Marselshoes в отношении ваших личных данных, основана на нормах
                законодательства Республики Беларусь, международного
                законодательства в области защиты личных данных. Эти принципы и
                нормы применяются к обработке ваших личных данных при
                пользовании ресурсом Marselshoes и его посещении для защиты
                вашей частной жизни.
              </p>
              <p className="MsoNormal">
                Когда мы используем «Сайт», «мы», «нас» или «наш», мы имеем в
                виду{" "}
                <span lang="EN-US" className={"zx-9"}>
                  Marselshoes
                </span>
                . Когда мы используем «вы» или «ваш», мы подразумеваем вас,
                лицо, воспользовавшееся нашими услугами.
              </p>
              <p className="MsoNormal">
                Целью Политики является информирование вас о том, как мы будем
                использовать ваши персональные данные, полученные нами от вас
                через Сайт. Пожалуйста, внимательно ознакомьтесь с ней, прежде
                чем продолжить использование Сайта. Посещая наш Сайт, вы
                обязуетесь соблюдать настоящую Политику и соглашаетесь с ее
                условиями.
              </p>
              <p className="MsoNormal">
                Пользуясь ресурсом Marselshoes, вы доверяете нам ваши личные
                данные. Доверие наших пользователей имеет для нас первостепенное
                значение, и поэтому мы стремимся защищать ваше право на
                конфиденциальность.
              </p>
              <p className="MsoNormal">
                Если вы не принимаете условия нашей политики конфиденциальности,
                мы просим вас воздержаться от пользования сервисом{" "}
                <span lang="EN-US" className={"zx-9"}>
                  Marselshoes
                </span>
                .
              </p>
              <p className="MsoNormal">
                Мы обрабатываем личные данные пользователей с целью улучшения и
                совершенствования нашего сервиса.
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                СОГЛАСИЕ НА ОБРАБОТКУ
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                ПЕРСОНАЛЬНЫХ ДАННЫХ
              </p>
              <p className="MsoNormal">
                Предоставляя свое согласие на обработку персональных данных при
                регистрации на данном Сайте, оформлении заказа или при
                направлении сообщений через форму обратной связи, вы
                соглашаетесь с Политикой конфиденциальности и ее применением при
                обработке ваших персональных данных.
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                КАКИЕ ЛИЧНЫЕ ДАННЫЕ О НАШИХ ПОЛЬЗОВАТЕЛЯХ МЫ СОБИРАЕМ
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                И КАК МЫ ИХ ИСПОЛЬЗУЕМ
              </p>
              <p className="MsoNormal">
                Вы можете пользоваться большей частью нашего Сайта без
                предоставления каких-либо персональных данных. Однако если вы
                хотите отправить нам сообщение или пообщаться онлайн, получать
                рассылку или специальные предложения по электронной почте,
                заказать товар или услуги, тогда вам потребуется предоставить
                определённую информацию.
              </p>
              <p className="MsoNormal">
                Ваши персональные данные могут включать ваши имя, фамилию, адрес
                или месторасположение, номер телефона и адрес электронной почты,
                текст вашего сообщения или любую другую информацию, которую вы
                нам присылаете, используя форму обратной связи.
              </p>
              <p className="MsoNormal">
                <span className={"zx-10"}>&nbsp;</span>Мы также сохраняем
                информацию о товарах, приобретённых вами. Таким образом, мы
                можем быстрее разобраться с любыми возникшими проблемами или
                запросами, а также удостовериться, что вся отсылаемая вам
                информация является релевантной.
              </p>
              <p className="MsoNormal">
                Когда вы посещаете наш Сайт, мы автоматически собираем
                определённые данные о вас:
              </p>
              <p className="MsoListParagraph zx-11">
                <span className={"zx-8"}>
                  <span className={"zx-13"}>
                    1.
                    <span className={"zx-12"}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                </span>
                Данные, самостоятельно вносимые пользователями.
              </p>
              <p className="MsoNormal" style={{ marginLeft: "18.0pt" }}>
                Персональные данные, самостоятельно вносимые вами при
                регистрации, заказе <span className={"zx-10"}>&nbsp;</span>
                товара. Например, фио, адрес, номер телефона и т.д. либо другую
                информацию, которую вы нам присылаете, используя обратную связь.
              </p>
              <p className="MsoListParagraph zx-11">
                <span className={"zx-8"}>
                  <span className={"zx-13"}>
                    2.
                    <span className={"zx-12"}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                </span>
                Данные, автоматически собираемые при использовании нашего Сайта.
              </p>
              <p className="MsoNormal" style={{ marginLeft: "18.0pt" }}>
                2.1 Данные технического характера об устройстве и
                интернет-подключении.
              </p>
              <p className="MsoNormal">
                <span className={"zx-10"}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
                С помощью логов сервера и других инструментов мы регистрируем
                данные о техническом устройстве, которым вы пользуетесь, и каким
                образом это устройство подключается к нашим сервисам, включая
                данные об операционной системе, версии браузера, IP-адресах,
                файлах cookie и уникальных идентификационных файлах. Обычно эти
                данные используются для получения анонимной (обезличенной) и
                совокупной статистики, но она также может быть связана с вашей
                учетной записью (профилем). Эта информация дает нам возможность
                анализировать, как вы пользуетесь нашими сервисами, и
                подстраивать их под ваше устройство в целях облегчения и
                удобства вашего пользования Сайтом.
              </p>
              <p className="MsoNormal" style={{ marginLeft: "18.0pt" }}>
                <span className={"zx-10"}>&nbsp;</span>2.2 Данные о
                местоположении
              </p>
              <p className="MsoNormal">
                <span className={"zx-10"}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
                Когда вы пользуетесь нашими сервисами, мы фиксируем ваше
                примерное географическое{" "}
                <span className={"zx-10"}>&nbsp;&nbsp;&nbsp;</span>
                местоположение, основанное на вашем IP-адресе.
                <span className={"zx-10"}>&nbsp; </span>Эта информация
                используется, например, для статистических исследований, а также
                для того, чтобы отображать актуальные для вас объявления,
                рекламу или другой персонализированный контент.
              </p>
              <p className="MsoNormal">
                <span className={"zx-10"}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
                2.3 Файлы cookie и прочий локально хранящийся контент.
              </p>
              <p className="MsoNormal">
                <span className={"zx-10"}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                Наш сайт использует файлы cookie и похожие технологии, чтобы
                гарантировать максимальное удобство для вас, предоставляя
                персонализированную информацию, запоминая предпочтения в области
                маркетинга и нашей продукции, а также помогая получить
                корректную информацию. Файл cookie представляет собой небольшой
                файл, обычно состоящий из букв и цифр. Этот файл сохраняется на
                вашем компьютере, планшетном ПК, телефоне или другом устройстве,
                которое вы используете для посещения сайта. Файлы cookie широко
                используются владельцами сайтов для обеспечения работы сайтов
                или повышения эффективности работы, а также для получения
                аналитической информации. Файлы cookie никогда не используются
                для запуска программ или для заражения вашего устройства
                вирусами.<span className={"zx-10"}>&nbsp; </span>Мы используем
                файлы cookie, например, для того, чтобы вам не нужно было каждый
                раз заново вводить данные для входа в профиль. Наш Сайт также
                отправляет вам файлы cookie, например, когда вы перемещаетесь по
                нашему ресурсу, осуществляете поиск или вносите ваши данные при
                регистрации и т.д. Файлы cookie позволяют сэкономить ваше время,
                поскольку они позволяют сохранять, а не вводить многократно одну
                и ту же информацию. Мы используем следующие виды файлов cookie:
                аналитические, социальных сетей, партнеров, cookies рекламы и
                поведения, технические и рабочие. Пользуясь нашими сервисами, вы
                выражаете ваше согласие на использование нами файлов cookie,
                если в настройках вашего браузера вы не деактивировали
                использование файлов cookie. Вы можете сами решить, согласны ли
                вы принимать файлы cookie или нет. Для этого вы должны
                соответствующим образом установить настройки браузера или не
                использовать сайт{" "}
                <span lang="EN-US" className={"zx-9"}>
                  Marselshoes
                </span>
                .
                <span lang="EN-US" className={"zx-9"}>
                  by
                </span>
                .<span style={{ textAlign: "center" }}>&nbsp;</span>
              </p>
              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ textAlign: "center" }}
                align="center"
              >
                КАКИМ ОБРАЗОМ МЫ ИСПОЛЬЗУЕМ
              </p>
              <p
                className="MsoListParagraphCxSpLast"
                style={{ textAlign: "center" }}
                align="center"
              >
                СОБРАННУЮ ИНФОРМАЦИЮ
              </p>
              <p className="MsoNormal">
                Мы обрабатываем ваши данные для того, чтобы оказывать вам
                услуги, улучшать и развивать наши сервисы (например, настроить
                их согласно вашим предпочтениям). Мы также обрабатываем личные
                данные, чтобы понимать, какие области Сайта для вас наиболее
                интересны. Анализируем информацию о вашем посещении страниц с
                целью совершенствования нашего Сайта. На основе собранной
                информации мы осуществляем рекламные кампании. Периодически мы
                направляем нашим пользователям письма маркетингового характера
                (рассылку) по электронной почте или по другим каналам (например,
                в социальных сетях), если вы не отказались от получения такой
                рассылки.<span className={"zx-10"}>&nbsp; </span>В письмах
                рекламного характера, которые вы получаете от нас, есть
                возможность отказаться от рассылки, нажав на специальную ссылку
                в письме. Целью обработки личных данных пользователей также
                является получение статистики о тенденциях в нашем сегменте,
                которую мы используем для того, чтобы совершенствовать Сайт, а
                также расширять круг наших продуктов и сервисов. Статистика
                может собираться с помощью анализа, который может проводиться
                как самим Сайтом, так и компаниями, к услугам которых мы
                прибегаем для этих целей. Такие компании анализируют информацию
                на основе конфиденциальности и удаляют ее после выполнения
                поставленной задачи.
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                БЕЗОПАСНОСТЬ ЛИЧНЫХ ДАННЫХ
              </p>
              <p className="MsoNormal">
                Мы храним и обрабатываем ваши личные данные надежным и
                безопасным образом. Мы следуем нашим процедурам, принципам и
                мерам, которые гарантируют конфиденциальность пользователей с
                учетом требований законодательства. Мы постоянно совершенствуем
                способы сбора, хранения и обработки ваших личных данных. Мы
                ограничиваем нашим сотрудникам, подрядчикам и партнерам доступ к
                личным данным, а также накладываем на них строгие договорные
                обязательства, за нарушение которых предусмотрена
                ответственность.
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                ИЗМЕНЕНИЕ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ
              </p>
              <p className="MsoNormal">
                Мы будем регулярно обновлять нашу Политику конфиденциальности,
                чтобы информировать вас обо всех изменениях в нашем подходе к
                обработке ваших личных данных. В случае принятия существенных
                изменений, мы будем информировать вас о них отдельно. Все
                остальные изменения Политики конфиденциальности отражаются на
                этой странице.
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                ЗАПРОС ОБ УДАЛЕНИИ РЕДАКТИРОВАНИИ ИЛИ УДАЛЕНИИ ЛИЧНЫХ ДАННЫХ
              </p>
              <p className="MsoNormal">
                Для предоставления, редактирования или удаления ваших данных вы
                можете обратиться в организацию ИП Ермакович Д.В по адресу:
                <span className={"zx-10"}>&nbsp; </span>г. Минск, ул. Казинца,
                дом 39, кв. 10, Республика Беларусь. Мы готовы предоставлять,
                редактировать и удалять ваши личные данные при условии, что
                такая просьба от вас не сопряжена для нас с чрезмерными
                техническими сложностями, не подвергает риску конфиденциальность
                других пользователей и не поступает слишком многократно. Вы
                также можете попросить нас не использовать ваши личные данные в
                целях прямого маркетинга. В таком случае ваши данные не будут
                использованы для этой цели.
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center", tabStops: "196.0pt" }}
                align="center"
              >
                КОММУНИКАЦИЯ И МАРКЕТИНГ
              </p>
              <p className="MsoNormal">
                Чтобы подписаться на нашу рассылку с информацией о наших новых
                товарах, услугах, интересном материале и событиях, чтобы
                получать специальные предложения по электронной почте или
                телефону, пожалуйста, заполните форму подписки. Если вы
                осуществляете покупки на нашем Сайте, мы также можем иногда
                использовать адрес Вашей электронной почты, чтобы выслать нашу
                рассылку и сообщить о специальных предложениях. Вы можете
                отказаться от таких коммуникаций в любое время через ссылку
                «Отписаться», которая будет частью любого отсылаемого
                электронного письма.
              </p>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confidential;
