import React from "react";
import { useSelector } from "react-redux";

import "./../catalog.scss";

const TypesParam = () => {
  const types = useSelector((state) => state.searchParameters.types);

  return (
    <div className={"w-filter"}>
      <div className={"filter-name dashed "}>
        <a href={`/catalog`} className="name__link">
          Тип обуви
        </a>
        <br />
      </div>

      <ul className={"ul-filters"}>
        <li>
          <a href="/catalog">Показать все</a>
        </li>
        {types &&
          types.length > 0 &&
          types.map((type) => (
            <li>
              <a href={`/catalog/${type.name}`}>{type.name}</a>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default TypesParam;
