import React from "react";

const SaleFlag = () => {
  return (
    <div
      style={{
        backgroundColor: "#bc3631",
        padding: "3px 5px",
        minWidth: "45px",
        fontSize: "12px",
        lineHeight: "14px",
        textAlign: "center",
        color: "#fff",
        position: "absolute",
        bottom: "5px",
        left: 0,
        zIndex: 1,
      }}
    >
      {`SALE`}
    </div>
  );
};

export default SaleFlag;
