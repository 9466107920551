import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSelectedSeasonAction,
  deleteSelectedSeasonAction,
  setSelectedSeasonsAction,
} from "../../../store/searchParametersReducer";

const SeasonsParam = () => {
  const seasons = useSelector((state) => state.searchParameters.seasons);
  const selectedSeasons = useSelector(
    (state) => state.searchParameters.selectedSeasons
  );

  const dispatch = useDispatch();

  const handleCheckboxChange = (season) => {
    if (selectedSeasons.includes(season)) {
      return dispatch(deleteSelectedSeasonAction(season));
    } else {
      return dispatch(addSelectedSeasonAction(season));
    }
  };

  return (
    <div className={"w-filter"}>
      {seasons.length > 0 && (
        <>
          <div className={"filter-name"}>
            <div className={"row row-filter-name justify-content-between"}>
              <div className={"col-auto"}>Сезон</div>
              <div className={"col-auto col-clear"}>
                <a
                  className={"clear-filter"}
                  onClick={() => {
                    dispatch(setSelectedSeasonsAction([]));
                  }}
                >
                  Сбросить
                </a>
              </div>
            </div>
          </div>
          {seasons.map((season) => (
            <div className="w-filter-checkbox">
              <div>
                <div className="input checkbox">
                  <input
                    id={`season[${season.id}]`}
                    type="checkbox"
                    className="checkbox__default checkbox__hidden"
                    name="seasons[]"
                    checked={selectedSeasons.includes(season.id)}
                    onChange={() => handleCheckboxChange(season.id)}
                  />
                  <div className="styled">
                    <label htmlFor={`season[${season.id}]`} className="frame">
                      <span className="inset"></span>
                    </label>
                  </div>
                  <label htmlFor={`season[${season.id}]`} className="label">
                    {season.name}
                  </label>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default SeasonsParam;
