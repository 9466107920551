import React from "react";
import TypesParam from "./typesParam";
import ColorsParam from "./colorsParam";
import SeasonsParam from "./seasonsParam";
import SizesParam from "./sizesParam";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PriceParam from "./priceParam";
import {
  setPriceFromAction,
  setPriceToAction,
  setSelectedColorsAction,
  setSelectedSeasonsAction,
  setSelectedSizesAction,
} from "../../../store/searchParametersReducer";

const FilterBox = ({ isRolled, isMobile, setIsRolled }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { type } = useParams();

  const selectedColors = useSelector(
    (state) => state.searchParameters.selectedColors
  );
  const selectedSizes = useSelector(
    (state) => state.searchParameters.selectedSizes
  );
  const selectedSeasons = useSelector(
    (state) => state.searchParameters.selectedSeasons
  );
  const priceFrom = useSelector((state) => state.searchParameters.priceFrom);
  const priceTo = useSelector((state) => state.searchParameters.priceTo);

  const handleSearch = () => {
    const colorParams = selectedColors.map((color) => `color=${color}`);
    const seasonParams = selectedSeasons.map((season) => `season=${season}`);
    const sizeParams = selectedSizes.map((size) => `size=${size}`);
    const priceFromParam = `price_from=${priceFrom}`;
    const priceToParam = `price_to=${priceTo}`;

    const allParams = [
      ...colorParams,
      ...seasonParams,
      ...sizeParams,
      priceFromParam,
      priceToParam,
    ];

    const queryString = allParams.join("&");
    type
      ? navigate(`/catalog/${type}?${queryString}`)
      : navigate(`/catalog?${queryString}`);
    if (isMobile) {
      setIsRolled();
    }
  };

  const handleClean = () => {
    dispatch(setPriceFromAction(0));
    dispatch(setPriceToAction(500));
    dispatch(setSelectedColorsAction([]));
    dispatch(setSelectedSeasonsAction([]));
    dispatch(setSelectedSizesAction([]));
  };

  const FilterBody = () => (
    <div
      className={"navigation-menu-body"}
      style={{
        paddingLeft: isMobile && "15px",
        paddingRight: isMobile && "15px",
      }}
    >
      <div className={"w-filters"}>
        <TypesParam />
        <PriceParam />
        <ColorsParam />
        <SeasonsParam />
        <SizesParam />

        <div className="w-filter">
          <div className="row short-gutters">
            <div className="col-6">
              <div className="w-button pt-10">
                <a
                  onClick={handleSearch}
                  className="button small block js-catalog-button-submit"
                >
                  Применить
                </a>
              </div>
            </div>
            <div className="col-6">
              <div className="w-button pt-10">
                <a
                  onClick={handleClean}
                  className="button small block gray js-catalog-button-clear"
                >
                  Сбросить
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isMobile ? (
        <div
          className={`navigation-menu-filter filters ${
            isRolled ? "_toggled" : ""
          }`}
        >
          <div
            className={`menu-layout body-layout ${isRolled ? "_toggled" : ""}`}
          ></div>
          <div className="mobile-name" style={{ height: "auto" }}>
            <div className="name-table">
              <div className="name-cell">Фильтр</div>
            </div>
            <a onClick={setIsRolled} className="close white"></a>
          </div>
          <FilterBody />
        </div>
      ) : (
        <FilterBody />
      )}
    </>
  );
};

export default FilterBox;
