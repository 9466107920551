import {
  setColorsAction,
  setSeasonsAction,
  setSizesAction,
  setTypesAction,
} from "../store/searchParametersReducer";
import { REACT_APP_API_URL } from "../index";

export const fetchTypes = () => {
  return (dispatch) => {
    fetch(`${REACT_APP_API_URL}/api/v1/type-category`)
      .then((response) => response.json())
      .then((json) => {
        json.results.sort((a, b) => a.id - b.id);
        dispatch(setTypesAction(json.results));
      })
      .catch((e) => dispatch(setTypesAction([])));
  };
};
export const fetchColors = () => {
  return (dispatch) => {
    fetch(`${REACT_APP_API_URL}/api/v1/color`)
      .then((response) => response.json())
      .then((json) => {
        json.results.sort((a, b) => a.id - b.id);
        dispatch(setColorsAction(json.results));
      })
      .catch((e) => dispatch(setColorsAction([])));
  };
};
export const fetchSeasons = () => {
  return (dispatch) => {
    fetch(`${REACT_APP_API_URL}/api/v1/season`)
      .then((response) => response.json())
      .then((json) => {
        json.results.sort((a, b) => a.id - b.id);
        dispatch(setSeasonsAction(json.results));
      })
      .catch((e) => dispatch(setSeasonsAction([])));
  };
};
export const fetchSizes = () => {
  return (dispatch) => {
    fetch(`${REACT_APP_API_URL}/api/v1/size`)
      .then((response) => response.json())
      .then((json) => {
        json.results.sort((a, b) => a.id - b.id);
        dispatch(setSizesAction(json.results));
      })
      .catch((e) => dispatch(setSizesAction([])));
  };
};
