import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { HOME_ROUTE } from "../../routes";
import CartItem from "./cartItem";

const CartList = () => {
  const navigate = useNavigate();
  const cartProducts = useSelector((state) => state.products.cartProducts);
  const idsCartProducts = useSelector(
    (state) => state.products.idsCartProducts
  );

  if (cartProducts.length === 0) {
    navigate(HOME_ROUTE);
  } else {
    return (
      <div className="container">
        <div className="w-cart-table-head">
          <div className="row row-cart-table head">
            <div className="col col-image">Товар</div>
            <div className="col col-content">
              <div className="row row-content">
                <div className="col col-name"></div>
                <div className="col col-features">
                  <div className="row">
                    <div className="col">Размер</div>
                    <div className="col">Цвет</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-price">Цена</div>
          </div>
        </div>
        {cartProducts.map((item) => (
          <CartItem
            product={item}
            size={
              idsCartProducts.find((id) => id.id === item.id)
                ? idsCartProducts.find((id) => id.id === item.id).size
                : ""
            }
          />
        ))}
      </div>
    );
  }
};

export default CartList;
