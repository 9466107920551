import React from "react";
import { useNavigate } from "react-router-dom";

const COMPANY_ITEMS = [
  { name: "О нас", link: "/about-us" },
  { name: "Оферта", link: "/offer" },
  { name: "Политика конфиденциальности", link: "/confidential" },
  { name: "Сертификаты", link: "/certificate" },
];

const FOR_CLIENTS_ITEMS = [
  { name: "Доставка и оплата", link: "/delivery" },
  { name: "Обмен и возврат", link: "/returns" },
  { name: "Безопасность и оплата", link: "/secure-pay" },
];

const MENU_BAR_ITEM = [
  { name: "Компания", items: COMPANY_ITEMS },
  { name: "Клиентам", items: FOR_CLIENTS_ITEMS },
];

const MenuBar = () => {
  const navigate = useNavigate();
  return (
    <div className="menu-bar">
      {MENU_BAR_ITEM.map((item) => (
        <div className="menu-bar_item">
          <div className="menu-bar_item_name">{item.name}</div>
          <ul className="menu-bar_item_list">
            {item.items.map((item) => (
              <li>
                <div
                  className={"menu-bar_item_list_item"}
                  onClick={() => navigate(item.link)}
                >
                  {item.name}
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default MenuBar;
