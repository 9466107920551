import React, { useState } from "react";

import "./mobileMenu.scss";
import { NAV_ITEMS } from "./headerBottom";
import { useSelector } from "react-redux";

const MobileMenu2 = ({ close }) => {
  const types = useSelector((state) => state.searchParameters.types);
  const [isRolled, setIsRolled] = useState(true);
  return (
    <div className="s-mobile-menu _toggled">
      <div className="w-mobile-menu">
        <div className="mobile-menu-header">
          <div className="table">
            <div className="mobileCell">
              {` Меню `}
              <div className="customize-css-icon">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
              <a onClick={close} className="close white"></a>
            </div>
          </div>
        </div>
        <div className="mobile-menu-body">
          <ul className="ul-mobile-menu">
            {NAV_ITEMS.map((item) => (
              <>
                {item.title === "Каталог" ? (
                  <li
                    className={`li-mobile-menu li-dropper ${
                      isRolled ? "_toggled" : ""
                    }`}
                  >
                    <a href="/catalog" className="mobile-menu__link">
                      Каталог
                    </a>
                    <div
                      onClick={() => setIsRolled(!isRolled)}
                      className={`b-dropper ${isRolled ? "_toggled" : ""}`}
                    ></div>
                    <div
                      className="inset"
                      style={{ display: isRolled ? "block" : "none" }}
                    >
                      <ul className="ul-inset">
                        <li className="li-inset">
                          <a href="/catalog">Показать все</a>
                        </li>
                        {types.map((type) => (
                          <li className="li-inset">
                            <a href={`/catalog/${type.name}`}>{type.name}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                ) : (
                  <li className="li-mobile-menu">
                    <a href={item.link} className="mobile-menu__link">
                      {item.title}
                    </a>
                  </li>
                )}
              </>
            ))}
          </ul>
        </div>
      </div>
      <div className="mobile-menu-background"></div>
    </div>
  );
};

export default MobileMenu2;
