import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTE } from "../../routes";

import FavoriteItem from "./favoriteItem";

const FavoriteList = () => {
  const navigate = useNavigate();
  const favoriteProducts = useSelector(
    (state) => state.products.favoriteProducts
  );
  console.log(favoriteProducts);
  if (favoriteProducts.length === 0) {
    navigate(HOME_ROUTE);
  } else {
    return (
      <div className="container">
        <div className="w-cart-table-head">
          <div className="row row-cart-table head">
            <div className="col col-image">Товар</div>
            <div className="col col-content">
              <div className="row row-content">
                <div className="col col-name"></div>
                <div className="col col-features">
                  <div className="row">
                    <div className="col">Размер</div>
                    <div className="col">Цвет</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-price">Цена</div>
          </div>
        </div>
        {favoriteProducts.map((item) => (
          <FavoriteItem product={item} />
        ))}
      </div>
    );
  }
};

export default FavoriteList;
