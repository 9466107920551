import React from "react";
import { NavLink } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import "./header.scss";

const DEFAULT_CLASSNAME = "header";

const NAV_ITEMS = [
  { title: "Каталог", link: "/catalog" },
  { title: "Новинки", link: "/novelty" },
  { title: "Распродажа", link: "/sellout" },
  // {title: 'Доставка и оплата', link: 'billing'},
  { title: "Контакты", link: "/contact" },
];

const MobileMenu = () => {
  return (
    <div className={`${DEFAULT_CLASSNAME}_mobile_menu`}>
      <Menu>
        {NAV_ITEMS.map((item, id) => (
          <NavLink
            to={item.link}
            key={id.toString()}
            className={`${DEFAULT_CLASSNAME}_wrapper_navigation_item`}
            activeClassName="selected"
          >
            {item.title}
          </NavLink>
        ))}
      </Menu>
    </div>
  );
};

export default MobileMenu;
