import React, { useState } from "react";
import ModalSizeTable from "./modal/modalSizeTable";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSizeAction } from "../../../store/catalogReducer";

const sizesArray = [35, 36, 37, 38, 39, 40, 41];

const SizeFragment = () => {
  const dispatch = useDispatch();
  const selectedSize = useSelector((state) => state.products.selectedSize);
  let availableSizes = useSelector((state) => state.products.availableSizes);
  const [isModalVisible, setIsModalVisible] = useState(false);
  if (!availableSizes) availableSizes = [36, 37, 40];
  const handleCellSelect = (number) => {
    if (availableSizes.includes(number)) {
      selectedSize === number
        ? dispatch(setSelectedSizeAction(null))
        : dispatch(setSelectedSizeAction(number));
    }
  };

  return (
    <div>
      <div className={"fragment_size"}>
        <div className={"fragment_title"}>Размер</div>
        <div
          className={"size_table_name"}
          onClick={() => setIsModalVisible(true)}
        >
          <div className={"size_table_name_text"}>{`Таблица размеров`}</div>
        </div>
      </div>
      <div style={{ padding: "2px 120px 26px 0" }}>
        <div className={"mb-20"}>
          <div className="grid">
            {sizesArray.map((number) => (
              <div
                className={`cell ${selectedSize === number ? "selected" : ""} ${
                  availableSizes.includes(number) ? "clickable" : ""
                }`}
                onClick={() => handleCellSelect(number)}
              >
                {number}
              </div>
            ))}
          </div>
        </div>
      </div>

      {isModalVisible && (
        <ModalSizeTable onClose={() => setIsModalVisible(false)} />
      )}
    </div>
  );
};

export default SizeFragment;
