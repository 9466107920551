import React, { useEffect, useState } from "react";
import { REACT_APP_API_URL } from "../../index";
import noPhoto from "../product/nophoto.png";
import { useSelector } from "react-redux";

const Slider = () => {
  const srcArray = [
    "https://marselshoes.by/storage/sliders/September2023/rDjvQPG5VVED4e4lRAJO.png",
    "https://marselshoes.by/storage/sliders/September2023/OABjKzhdoa0giCDMx21M.png",
    // Добавьте остальные URL изображений
  ];

  const photosForMainSlider = useSelector(
    (state) => state.products.photosForMainSlider
  );

  const images = [];
  photosForMainSlider.map((image) => {
    images.push(`${REACT_APP_API_URL}product_images/${image}`);
  });
  if (images.length === 0) {
    images.push(srcArray[0]);
  }

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="s-line s-index-slider">
      <div className="container">
        <div className="w-index-slider">
          <div className="w-slider">
            <div
              className="owl-carousel owl-index-slider owl-loaded owl-drag"
              data-owl-carousel='{"items":1,"loop":false,"dotsContainer":".index-slider-dots","responsive":null}'
            >
              <div className="owl-stage-outer">
                <div
                  className="owl-stage"
                  style={{
                    transform: "translate3d(0px, 0px, 0px)",
                    transition: "all 0s ease 0s",
                    width: "100%",
                  }}
                >
                  <div
                    className="owl-item active"
                    style={{ width: "100%", marginRight: "7px" }}
                  >
                    <div className="slide slide-double-pictures">
                      <div className="desktop">
                        <img
                          src={images[currentImageIndex]}
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div className="mobile">
                        <img
                          src={images[currentImageIndex]}
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-nav disabled">
                <div className="owl-prev"></div>
                <div className="owl-next"></div>
              </div>
              <div
                className="index-slider-dots align-center"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                {images.map((_, index) => (
                  <div
                    key={index}
                    className={`dot ${
                      index === currentImageIndex ? "active" : ""
                    }`}
                    onClick={() => setCurrentImageIndex(index)}
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
