import React from "react";
import MenuBar from "../../components/information/menuBar";

import "../../components/information/informations.scss";
import Title from "../../components/common/title";

const ThanksForOrdering = () => {
  return (
    <div className={"information"}>
      <Title str={"Благодарим за заказ"} />
      <div className={"product_info"}>
        <div style={{ padding: "0 15px 40px" }}>
          <MenuBar />
        </div>
        <div className="col col-content">
          <h1 className="pagetitle pb-20">Благодарим за заказ</h1>
          <article className="bigger">Благодарим за заказ</article>
        </div>
      </div>
    </div>
  );
};

export default ThanksForOrdering;
