import React from "react";

const ContactsItem = () => {
  return (
    <div
      className="col-content col"
      style={{ paddingBottom: 0, paddingTop: "10px" }}
    >
      <h1 className="pagetitle pb-20">Контакты</h1>
      <div className="w-contacts-block">
        {/*<div className="section-name _h4 uppercase bold mb-10">*/}
        {/*  Интернет-магазин*/}
        {/*</div>*/}
        <div className="description">
          Мы будем рады ответить на ваши вопросы 7 дней в неделю с 10:00 до
          20:00
        </div>
        {/*<div className="mt-10">*/}
        {/*  <div className="phone">*/}
        {/*    <a href="tel:+375296737396" className="phone__link">*/}
        {/*      +375 29 673-73-96*/}
        {/*    </a>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="phone">*/}
        {/*  <a href="tel:+375296737315" className="phone__link">*/}
        {/*    +375 29 673-73-15*/}
        {/*  </a>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default ContactsItem;
