import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import search from "./icons/search.svg";

const DEFAULT_CLASSNAME = "header";

const UpperSearchField = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [isInputVisible, setInputVisible] = useState(false);

  const handleSearch = () => {
    if (isInputVisible) {
      setInputVisible(false);
      navigate(`/search?keywords=${searchText}`);
    } else {
      setInputVisible(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <form
      className="col-auto col-search _js-mobile-search _toggled"
      id="form_search"
      method="GET"
      action="/search"
      style={{ width: "90px" }}
    >
      <div className="w-search">
        <div className="search">
          <div className={`${DEFAULT_CLASSNAME}_wrapper_control-panel_search`}>
            {isInputVisible && (
              <input
                onInput={(e) => setSearchText(e.currentTarget.value)}
                type="text"
                placeholder="Поиск по сайту"
                onKeyPress={handleKeyPress}
                style={{
                  backgroundColor: "#FFFFFF",
                  marginLeft: "30px",
                  width: "190px",
                }}
              />
            )}
            <img
              className={`${DEFAULT_CLASSNAME}_wrapper_control-panel_search_icon`}
              src={search}
              alt={"search-icon"}
              onClick={handleSearch}
              style={{
                right: isInputVisible ? "27px" : "unset",
                left: isInputVisible ? "unset" : "30px",
              }}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpperSearchField;
