import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/appRouter";
import { Header } from "./components/common/header/header";
import { Footer } from "./components/common/footer/footer";
import Cookies from "js-cookie";
import {
  setIdsCartProductsAction,
  setIdsFavoriteProductsAction,
} from "./store/catalogReducer";
import { useDispatch } from "react-redux";
import Title from "./components/common/title";

export const PhoneNumber1 = () => {
  return <a href={"tel:375291553020"}>{"+375 (29) 155-30-20"}</a>;
};
export const PhoneNumber2 = () => {
  return <a href={"tel:375297555562"}>{"+375 (29) 755-55-62"}</a>;
};
export const Email = () => {
  return <a href={"mailto:dreamstoreby@gmail.com"}>{"del_corso@gmail.com"}</a>;
};

export const WORK_SCHEDULE = "Пн-Вс: 10:00-20:00";
const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const favorites = Cookies.get("favoriteProductId");
    if (favorites) {
      const idsString = favorites.split(".");
      const ids = idsString.map(Number);
      dispatch(setIdsFavoriteProductsAction(ids));
    }
    const cartProducts = Cookies.get("cartProducts");
    if (cartProducts) {
      const items = cartProducts.split(".");

      const resultArray = items.map((part) => {
        const [id, size] = part.split("-").map(Number);
        return { id, size };
      });
      dispatch(setIdsCartProductsAction(resultArray));
    }
  }, []);

  return (
    <BrowserRouter>
      <Title str={"DEL-CORSO"} />
      <Header />
      {/* <NavPanel /> */}
      <AppRouter />
      {/*<ToastContainer />*/}
      <Footer />
    </BrowserRouter>
  );
};

export default App;
