// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.absolute-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0.1);
}

.w-insta-list .col {
  padding-bottom: 30px;
}

.w-insta-item .absolute-overlay {
  opacity: 1;
  transition: ALL 0.2s ease;
  -webkit-transition: ALL 0.2s ease;
  -moz-transition: ALL 0.2s ease;
  -o-transition: ALL 0.2s ease;
}

.w-insta-item a:hover .absolute-overlay {
  opacity: 0;
}

@media (max-width: 991px) {
  .w-insta-list .row {
    margin-left: -8px;
    margin-right: -8px;
  }
  .w-insta-list .col {
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/home/instagram.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,oCAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AACA;EACE,UAAA;EACA,yBAAA;EACA,iCAAA;EACA,8BAAA;EACA,4BAAA;AAEF;;AACA;EACE,UAAA;AAEF;;AACA;EACE;IACE,iBAAA;IACA,kBAAA;EAEF;EACA;IACE,iBAAA;IACA,kBAAA;IACA,oBAAA;EACF;AACF","sourcesContent":[".absolute-overlay {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  content: \"\";\n  background-color: rgba(0,0,0,.1)\n}\n\n.w-insta-list .col {\n  padding-bottom: 30px\n}\n.w-insta-item .absolute-overlay {\n  opacity: 1;\n  transition: ALL .2s ease;\n  -webkit-transition: ALL .2s ease;\n  -moz-transition: ALL .2s ease;\n  -o-transition: ALL .2s ease\n}\n\n.w-insta-item a:hover .absolute-overlay {\n  opacity: 0\n}\n\n@media(max-width: 991px) {\n  .w-insta-list .row {\n    margin-left: -8px;\n    margin-right: -8px\n  }\n\n  .w-insta-list .col {\n    padding-left: 8px;\n    padding-right: 8px;\n    padding-bottom: 16px\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
