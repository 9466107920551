import React from "react";

const NewFlag = () => {
  return (
    <div
      style={{
        backgroundColor: "#3B8777",
        padding: "3px 5px",
        minWidth: "45px",
        fontSize: "12px",
        lineHeight: "14px",
        textAlign: "center",
        color: "#FFF",
        position: "absolute",
        bottom: "5px",
        left: 0,
        zIndex: 1,
      }}
    >
      {`NEW`}
    </div>
  );
};

export default NewFlag;
