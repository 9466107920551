import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllSaleProducts } from "../asyncActions/catalog";
import SelloutList from "../components/sellout/selloutList";
import { fetchTypes } from "../asyncActions/searchParameters";
import { useParams } from "react-router-dom";
import Title from "../components/common/title";

const Sellout = () => {
  const saleProducts = useSelector((state) => state.products.saleProducts);
  const types = useSelector((state) => state.searchParameters.types);

  const { type } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTypes());
    const query = type ? `?type=${type}` : "";
    dispatch(fetchAllSaleProducts(query));
  }, []);

  return (
    <div className={"catalog"}>
      <Title
        str={"Распродажа на распродаже в интернет-магазине Del-corso.by"}
      />
      <>
        <div className={"catalog_info col col-side-content"}>
          <div className={"catalog_info_title"}>{`Распродажа`}</div>
          <div
            style={{
              marginBottom: "15px",
              fontSize: "16px",
              color: "#606060",
            }}
          >
            {saleProducts ? `${saleProducts.length}` : 0}{" "}
            {saleProducts &&
            (saleProducts.length % 10 === 2 ||
              saleProducts.length % 10 === 3) &&
            saleProducts.length !== 12 &&
            saleProducts.length !== 13
              ? "позиции"
              : saleProducts && saleProducts.length % 10 === 1
              ? "позиция"
              : "позиций"}
          </div>

          <div className="w-catalog-tags-list">
            <div className="row">
              <div className="col-auto">
                <a href="/sellout" className="tag__link ">
                  Все товары
                </a>
              </div>
              {types.map((type) => (
                <div className="col-auto">
                  <a href={`/sellout/${type.name}`} className="tag__link ">
                    {type.name}
                  </a>
                </div>
              ))}
            </div>
          </div>

          <SelloutList />
          <div className="mb-10">
            <hr />
          </div>
        </div>
      </>
    </div>
  );
};

export default Sellout;
