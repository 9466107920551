import React from "react";
import { useSelector } from "react-redux";
import CatalogItem from "../catalog/catalogItem";

const NoveltyList = () => {
  const noveltyProducts = useSelector(
    (state) => state.products.noveltyProducts
  );
  return (
    <div>
      <div className={`row`}>
        {noveltyProducts.map((product) => (
          <CatalogItem product={product} isCatalog={false} isHome={false} />
        ))}
      </div>
    </div>
  );
};

export default NoveltyList;
