import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCartProducts,
  fetchFavoriteProducts,
} from "../asyncActions/catalog";

import "./../components/deferred/deferred.scss";
import SelectorDeferredType from "../components/deferred/selectorDeferredType";
import FavoriteList from "../components/deferred/favoriteList";

const Favorites = () => {
  const dispatch = useDispatch();
  const favoriteProducts = useSelector(
    (state) => state.products.favoriteProducts
  );
  const idsCartProducts = useSelector(
    (state) => state.products.idsCartProducts
  );
  const idsFavoriteProducts = useSelector(
    (state) => state.products.idsFavoriteProducts
  );

  useEffect(() => {
    if (idsCartProducts && idsCartProducts.length > 0) {
      dispatch(fetchCartProducts(idsCartProducts.map((item) => item.id)));
    }
    if (idsFavoriteProducts && idsFavoriteProducts.length > 0) {
      dispatch(fetchFavoriteProducts(idsFavoriteProducts));
    }
  }, [idsCartProducts, idsFavoriteProducts]);

  return (
    <div className={"catalog row"}>
      <section className="s-line s-cart-top-tabs pb-20">
        <div className="container">
          <SelectorDeferredType isCart={false} />
          <div className="w-favorites-description">
            {`В данном разделе собраны все товары, которые вы добавили в
		          Избранное. Редактируйте подборку, выбирайте новые изделия и
		          переносите их в Корзину.`}
          </div>
        </div>
      </section>
      <section className="s-line s-cart-table">
        {favoriteProducts && favoriteProducts.length > 0 && <FavoriteList />}
      </section>
      <section className="s-line s-cart-total pt-50 pb-50 hide-on-phone">
        <div className="container">
          <div className="row row-cart-total align-items-center justify-content-between">
            <div className="col col-auto col-back-to">
              <a href="/catalog" className="back-to-shopping__link">
                <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                <span className="text">Продолжить покупки</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className={"container"}>
        <hr />
      </div>

      <section className="s-line s-cart-form pt-50 pb-30">
        <div className="container">
          <div className="row row-cart-form justify-content-between">
            <div className="col-12 col col-description pb-40">
              <div className="section-name _h3 uppercase bold mb-20">
                <img
                  src="https://marselshoes.by/i/attention.png"
                  alt="attention"
                  style={{ verticalAlign: "middle", marginTop: "-5px" }}
                  loading="lazy"
                />{" "}
                ВАЖНАЯ ИНФОРМАЦИЯ ДЛЯ КЛИЕНТОВ ИЗ РОССИИ
              </div>
              <article className="bigger">
                <p>
                  Товар не облагается пошлиной, если общая стоимость заказа не
                  превышает сумму, эквивалентную 1000 евро, а общий вес - 31 кг
                  за один календарный месяц. В случае превышения указанных норм
                  предусмотрены пошлины на импорт в размере до 30% от таможенной
                  стоимости товара, превышающей 1000 евро.
                </p>
                <p>
                  Таможня рассчитывает пошлины исходя из полной стоимости
                  товара, невзирая на скидки, которые мы вам предоставили.
                  Доставка занимает 2-4 дня; ожидайте СМС от DHL с запросом
                  копии паспорта для таможенного оформления. Каждый бутик в
                  вашем заказе сформирует отдельную посылку.
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Favorites;
