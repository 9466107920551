import React, { useEffect, useState } from "react";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import CatalogItem from "../catalog/catalogItem";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllNoveltyProducts } from "../../asyncActions/catalog";
const NoveltyList = () => {
  const noveltyProducts = useSelector(
    (state) => state.products.noveltyProducts
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllNoveltyProducts());
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [startIndex, setStartIndex] = useState(0);

  const totalImages = noveltyProducts.length;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const numVisibleThumbnails =
    windowWidth > 990
      ? totalImages > 4
        ? 4
        : totalImages
      : windowWidth > 767
      ? 2
      : 1;

  const generateThumbnailIndexes = () => {
    const indexes = [];
    for (let i = 0; i < numVisibleThumbnails; i++) {
      const index = (startIndex + i) % totalImages;
      indexes.push(index);
    }
    return indexes;
  };

  return (
    <div className="s-line s-moreproducts-slider pt-10 pb-10">
      <div className="container" style={{ margin: "0,50px" }}>
        <div className="w-more-products-slider">
          <div className="section-name _h3 uppercase bold align-center mb-10">
            Новинки
          </div>
          <div className="mb-10 align-center"></div>
          <div
            className="dots-container align-center"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div
              className="owl-dot slider-prev "
              onClick={() => {
                let newStartIndex = startIndex - 1;
                if (newStartIndex === -1) {
                  newStartIndex = totalImages - 1;
                }
                setStartIndex(newStartIndex);
              }}
            >
              {" "}
              <HiArrowLongLeft
                style={{ fontSize: "30px", marginRight: "12px" }}
              />
            </div>
            <div
              className="owl-dot slider-next"
              onClick={() => {
                setStartIndex((startIndex + 1) % totalImages);
              }}
            >
              <HiArrowLongRight
                style={{ fontSize: "30px", marginLeft: "12px" }}
              />
            </div>
          </div>
          <div
            className="owl-carousel owl-more-products-slider owl-custom-dots-slider owl-loaded owl-drag"
            data-owl-carousel=""
          >
            <div className="owl-stage-outer">
              <div
                className="owl-stage"
                style={{
                  // transform: "translate3d(-1680px, 0px, 0px)",
                  transition: "all 0s ease 0s",
                  // width: "6440px",
                }}
              >
                <div className={`row`}>
                  {generateThumbnailIndexes().map((product) => (
                    <CatalogItem
                      product={noveltyProducts[product]}
                      isCatalog={false}
                      isHome={true}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoveltyList;
