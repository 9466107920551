import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import { routes } from "../routes";

const AppRouter = () => {
  return (
    <Routes>
      {routes.map(({ path, Component }) => (
        <Route key={path} path={path} element={<Component />} exact />
      ))}
      <Route
        path="*"
        element={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <h3>404. Страница не найдена</h3>
            <Link style={{ color: "#0866D7", fontWeight: "400" }} to={"/"}>
              Вернуться на главную
            </Link>
          </div>
        }
      />
    </Routes>
  );
};

export default AppRouter;
