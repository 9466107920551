import React, { useEffect } from "react";

import "./../components/deferred/deferred.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCartProducts,
  fetchFavoriteProducts,
} from "../asyncActions/catalog";
import SelectorDeferredType from "../components/deferred/selectorDeferredType";
import CartList from "../components/deferred/cartList";
import ClientsInfo from "../components/deferred/clientsInfo";
import OrderForm from "../components/deferred/orderForm";

const Cart = () => {
  const dispatch = useDispatch();
  const idsCartProducts = useSelector(
    (state) => state.products.idsCartProducts
  );
  const idsFavoriteProducts = useSelector(
    (state) => state.products.idsFavoriteProducts
  );
  const cartProducts = useSelector((state) => state.products.cartProducts);

  useEffect(() => {
    dispatch(fetchCartProducts(idsCartProducts.map((item) => item.id)));
    dispatch(fetchFavoriteProducts(idsFavoriteProducts));
  }, [idsCartProducts]);

  return (
    <div className={"catalog row"}>
      <section className="s-line s-cart-top-tabs pb-20">
        <div className="container">
          <SelectorDeferredType isCart={true} />
        </div>
      </section>
      <section className="s-line s-cart-table">
        {cartProducts && cartProducts.length > 0 && <CartList />}
      </section>
      <section className="s-line s-cart-total pt-20 pb-20 hide-on-phone">
        <div className="container">
          <div className="row row-cart-total align-items-center justify-content-between">
            <div className="col col-auto col-back-to">
              <a href="/catalog" className="back-to-shopping__link">
                <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                <span className="text">Продолжить покупки</span>
              </a>
            </div>
            <div className="col col-auto">
              <div className="row align-items-end justify-content-between">
                <div className="col-auto">
                  <div className="description pt-10 pb-10">ИТОГО: </div>
                </div>
                <div className="col-auto">
                  <div
                    className="total-price pt-10 pb-10 price"
                    data-cart-calculation-total-order-price=""
                  >
                    {cartProducts
                      ? cartProducts
                          .reduce((accumulator, currentItem) => {
                            return (
                              accumulator +
                              Number(
                                currentItem.discount.discount_price
                                  ? currentItem.discount.discount_price
                                  : currentItem.price
                              )
                            );
                          }, 0)
                          .toFixed(2)
                      : 0}{" "}
                    руб.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={"container"}>
        <hr />
      </div>
      <section className="s-line s-cart-form pt-50 pb-30">
        <div className="container">
          <div
            className="row row-cart-form justify-content-between"
            data-cart-delivery-block="BYN"
          >
            <div className="col-lg-6 col-md-6 col-12 col col-form pb-20">
              <div className="section-name _h3 uppercase bold mb-20">
                ИНФОРМАЦИЯ ДЛЯ оплаты и доставки заказа
              </div>
              <OrderForm />
            </div>
            <ClientsInfo />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Cart;
