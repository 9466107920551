import React from "react";

import "./footer.scss";
import { useNavigate } from "react-router-dom";
import {
  DELIVERY_ROUTE,
  RETURNS_ROUTE,
  SECURE_PAY_ROUTE,
} from "../../../routes";

const DEFAULT_CLASSNAME = "footer";

const UNIT_ITEMS = [
  { title: "Доставка и оплата", link: DELIVERY_ROUTE },
  { title: "Обмен и возврат", link: RETURNS_ROUTE },
  { title: "Безопасность и оплата", link: SECURE_PAY_ROUTE },
];

const ClientInfoUnit = () => {
  const navigate = useNavigate();
  return (
    <div className={`${DEFAULT_CLASSNAME}_navigation_item`}>
      <div className={`${DEFAULT_CLASSNAME}_navigation_sub-item-title`}>
        {"КЛИЕНТАМ"}
      </div>
      {UNIT_ITEMS.map((item, id) => (
        <div
          key={id.toString()}
          className={`${DEFAULT_CLASSNAME}_navigation_sub-item`}
          onClick={() => navigate(item.link)}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};

export default ClientInfoUnit;
