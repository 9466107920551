import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import search from "./icons/search.svg";

import "./header.scss";
const DEFAULT_CLASSNAME = "header";

const SearchField = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [isInputVisible, setInputVisible] = useState(false);

  const handleMouseLeave = () => {
    if (
      !document.activeElement ||
      document.activeElement.tagName.toLowerCase() !== "input"
    ) {
      setInputVisible(false);
    }
  };

  const handleSearch = () => {
    navigate(`/search?keywords=${searchText}`);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div
      className={`${DEFAULT_CLASSNAME}_wrapper_control-panel_search`}
      onMouseLeave={handleMouseLeave}
    >
      {isInputVisible && (
        <input
          onInput={(e) => setSearchText(e.currentTarget.value)}
          type="text"
          placeholder="Поиск по сайту"
          onBlur={() => setInputVisible(false)}
          onKeyPress={handleKeyPress}
          style={{ backgroundColor: "#FFFFFF" }}
        />
      )}
      <img
        className={`${DEFAULT_CLASSNAME}_wrapper_control-panel_search_icon`}
        src={search}
        alt={"search-icon"}
        onMouseEnter={() => setInputVisible(true)}
        onClick={handleSearch}
      />
    </div>
  );
};

export default SearchField;
