import React, { useEffect } from "react";
import Cookies from "js-cookie";
import {
  addIdFavoriteProductAction,
  deleteIdFavoriteProductAction,
} from "../../store/catalogReducer";
import { useDispatch, useSelector } from "react-redux";
import { HiStar, HiOutlineStar } from "react-icons/hi2";

const FavoriteStar = ({ productId }) => {
  const dispatch = useDispatch();
  const idsFavoriteProducts = useSelector(
    (state) => state.products.idsFavoriteProducts
  );
  useEffect(() => {
    if (idsFavoriteProducts.length > 0) {
      const myString = idsFavoriteProducts.join(".");
      Cookies.set("favoriteProductId", myString);
    } else {
      Cookies.remove("favoriteProductId");
    }
  }, [idsFavoriteProducts]);

  const handleToggleFavorite = (productId) => {
    if (idsFavoriteProducts.includes(productId)) {
      dispatch(deleteIdFavoriteProductAction(productId));
    } else {
      dispatch(addIdFavoriteProductAction(productId));
    }
  };

  return (
    <div
      style={{
        padding: "6px 10px 10px",
        fontSize: "24px",
        alignItems: "center",
        textAlign: "center",
        color: "#cfcfcf",
        position: "absolute",
        top: "3px",
        left: 0,
        zIndex: 1,
        cursor: "pointer",
      }}
      onClick={(e) => {
        e.stopPropagation();
        handleToggleFavorite(productId);
      }}
    >
      {idsFavoriteProducts.includes(productId) ? <HiStar /> : <HiOutlineStar />}
    </div>
  );
};

export default FavoriteStar;
