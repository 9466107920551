import React, { useEffect, useState } from "react";
import FilterBox from "../components/catalog/filter/filterBox";
import {
  fetchColors,
  fetchSeasons,
  fetchSizes,
  fetchTypes,
} from "../asyncActions/searchParameters";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  setPriceFromAction,
  setPriceToAction,
  setSelectedColorsAction,
  setSelectedSeasonsAction,
  setSelectedSizesAction,
} from "../store/searchParametersReducer";
import { fetchAllProducts } from "../asyncActions/catalog";
import CatalogList from "../components/catalog/catalogList";
import LoadingComponent from "../components/common/loadingComponent";

import "./../components/catalog/catalog.scss";
import Title from "../components/common/title";

const Catalog = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { type } = useParams();

  const products = useSelector((state) => state.products.allProducts);
  const allColors = useSelector((state) => state.searchParameters.colors);
  const allSeasons = useSelector((state) => state.searchParameters.seasons);
  const allSizes = useSelector((state) => state.searchParameters.sizes);

  const queryParams = new URLSearchParams(location.search);

  const queryString = [];
  const colors = queryParams.getAll("color");
  dispatch(setSelectedColorsAction(colors.map(Number)));

  const seasons = queryParams.getAll("season");
  dispatch(setSelectedSeasonsAction(seasons.map(Number)));

  const sizes = queryParams.getAll("size");
  dispatch(setSelectedSizesAction(sizes.map(Number)));

  const priceFrom = queryParams.get("price_from");
  if (!!priceFrom) {
    dispatch(setPriceFromAction(Number(priceFrom)));
  }

  const priceTo = queryParams.get("price_to");
  if (!!priceTo) {
    dispatch(setPriceToAction(Number(priceTo)));
  }

  const getQueryString = () => {
    if (!!colors) {
      colors.map((color) => {
        const value = allColors.find((item) => item.id === Number(color));
        if (value) {
          queryString.push(`color=${value.color}`);
        }
      });
    }

    if (!!seasons) {
      seasons.map((season) => {
        const value = allSeasons.find((item) => item.id === Number(season));
        if (value) {
          queryString.push(`season=${value.name}`);
        }
      });
    }

    if (!!sizes) {
      sizes.map((size) => {
        const value = allSizes.find((item) => item.id === Number(size));
        if (value) {
          queryString.push(`size=${value.size}`);
        }
      });
    }
    if (!!priceFrom) {
      queryString.push(`min_price=${Number(priceFrom)}`);
    }
    if (!!priceTo) {
      queryString.push(`max_price=${Number(priceTo)}`);
    }
    if (!!type) {
      queryString.push(`type=${type}`);
    }

    return queryString.join("&");
  };

  const query = getQueryString();
  useEffect(() => {
    dispatch(fetchTypes());
    dispatch(fetchColors());
    dispatch(fetchSeasons());
    dispatch(fetchSizes());
  }, []);
  useEffect(() => {
    dispatch(fetchAllProducts(query));
  }, [query]);

  const [isRolled, setIsRolled] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const isMobile = windowWidth <= 990;

  return (
    <div className={"catalog"}>
      <Title
        str={"Обувь в Минске | Купить женскую обувь в интернет-магазине, цена"}
      />
      <>
        <div className={"w-page-double-columns"}>
          <div className={"row row-page-double-columns"}>
            {!isMobile ? (
              <div className={"col col-side-filter"}>
                <FilterBox />
              </div>
            ) : (
              isRolled && (
                <div className={"col col-side-filter"}>
                  <FilterBox
                    isRolled={isRolled}
                    isMobile={isMobile}
                    setIsRolled={() => setIsRolled(false)}
                  />
                </div>
              )
            )}
            <div className={"col col-side-content catalog_info"}>
              <div className={"catalog_info_title"}>
                {!type ? `Женская обувь:` : `Женские ${type}:`}
              </div>

              {products.length >= 0 ? (
                <CatalogList filterIsRolled={() => setIsRolled(!isRolled)} />
              ) : (
                <LoadingComponent />
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Catalog;
