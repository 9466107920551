import React from "react";

import {
  ABOUT_US_ROUTE,
  CERTIFICATE_ROUTE,
  CONFIDENTIAL_ROUTE,
  OFFER_ROUTE,
} from "../../../routes";

import "./footer.scss";
import { useNavigate } from "react-router-dom";

const DEFAULT_CLASSNAME = "footer";

const UNIT_ITEMS = [
  { title: "О нас", link: ABOUT_US_ROUTE },
  { title: "Оферта", link: OFFER_ROUTE },
  { title: "Политика конфиденциальности", link: CONFIDENTIAL_ROUTE },
  { title: "Сертификаты", link: CERTIFICATE_ROUTE },
];

const ContactInfoUnit = () => {
  const navigate = useNavigate();
  return (
    <div className={`${DEFAULT_CLASSNAME}_navigation_item`}>
      <div className={`${DEFAULT_CLASSNAME}_navigation_sub-item-title`}>
        {"КОМПАНИЯ"}
      </div>
      {UNIT_ITEMS.map((item, id) => (
        <div
          key={id.toString()}
          className={`${DEFAULT_CLASSNAME}_navigation_sub-item`}
          onClick={() => navigate(item.link)}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};

export default ContactInfoUnit;
