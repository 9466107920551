import React from "react";
import MenuBar from "../../components/information/menuBar";

import "../../components/information/informations.scss";
import Title from "../../components/common/title";

const Returns = () => {
  return (
    <div className={"information"}>
      <Title str={"Обмен и возврат"} />
      <div className={"product_info"}>
        <div style={{ padding: "0 15px 40px" }}>
          <MenuBar />
        </div>
        <div className={"product_info_description"}>
          <div className="zx-6">
            <h1 className={"title"}>Обмен и возврат</h1>
            <article className={"bigger"}>
              <h2 style={{ textAlign: "left" }}>
                <span style={{ color: "#999999" }}>
                  ВОЗВРАТ&nbsp;ИЛИ ОБМЕН ТОВАРА НАДЛЕЖАЩЕГО КАЧЕСТВА ДЛЯ ГРАЖДАН
                  РБ
                </span>
              </h2>
              <p>
                &nbsp;
                <span style={{ color: "#000000" }}>
                  Возврат или обмен товаров производятся в соответствии с
                  Законом РБ о защите прав потребителей. Вы можете отказаться от
                  товара во время его получения при курьере, а также имеете
                  право вернуть его в течение 14 дней, не считая дня покупки,
                  при условии сохранения товарного вида. Обращаем ваше внимание
                  на то, что требование потребителя об обмене или возврате
                  товара подлежит удовлетворению, если товар не был в
                  употреблении, сохранены его потребительские свойства. (Ст.28
                  Закона РБ о ЗПП).
                </span>
              </p>
              <p>&nbsp;</p>
              <h2 style={{ textAlign: "left" }}>
                <span style={{ color: "#999999" }}>
                  ВОЗВРАТ ИЛИ ОБМЕН ТОВАРА НЕНАДЛЕЖАЩЕГО КАЧЕСТВА ДЛЯ ГРАЖДАН РБ
                </span>
              </h2>
              <p>
                &nbsp;
                <span style={{ color: "#000000" }}>
                  При возврате товара по браку, он принимается на проверку
                  качества с целью определение причины дефекта. Проверка
                  проводится в срок до 7 дней.
                </span>
              </p>
              <p>
                <span style={{ color: "#000000" }}>
                  В случае подтверждения брака, возврат денежных средств
                  покупателю осуществляется в соответствии с Законодательством
                  РБ. Если при проверке качества подлинность брака не
                  подтверждается, денежные средства покупателю не возвращаются.
                </span>
              </p>
              <p>&nbsp;</p>
              <h2 style={{ textAlign: "left" }}>
                <span style={{ color: "#999999" }}>
                  КАК ПРОИСХОДИТ ВОЗВРАТ ДЛЯ ГРАЖДАН РБ
                </span>
              </h2>
              <p>
                &nbsp;
                <span style={{ color: "#000000" }}>
                  Возврат товара надлежащего качества возможен в случае, если
                  сохранены его товарный вид, оригинальная упаковка, нет следов
                  носки в течении 14 дней с дня покупки.
                </span>
              </p>
              <p>
                <span style={{ color: "#000000" }}>
                  По адресу г.Минск ул.Немига 3&nbsp; ТЦ "Немига-3", первый этаж
                  павильон 48 ( с 11:00 до 20:00) без выходных.
                </span>
              </p>
              <p>
                <span style={{ color: "#000000" }}>
                  После получения пары и ее проверки, мы осуществляем возврат
                  денежных средств тем же способом, которым вы производили
                  оплату в срок не позднее 7 дней.
                </span>
              </p>
              <p>
                <span style={{ color: "#000000" }}>
                  Товар, бывший в употреблении, обмену или возврату не подлежит.
                </span>
              </p>
              <p>
                <span style={{ color: "#000000" }}>
                  Возврат денежных средств осуществляется в течение 7 дней, если
                  оплата осуществлялась за наличный расчет.
                </span>
              </p>
              <p>
                <span style={{ color: "#000000" }}>
                  Если товар был оплачен банковской картой через сайт, то
                  возврат осуществляется на карту, с которой была произведена
                  оплата.
                </span>
              </p>
              <p>
                <span style={{ color: "#000000" }}>
                  Срок поступления денежных средств на карту от 3 до 30 дней с
                  момента осуществления возврата Продавцом.
                </span>
              </p>
              <p>&nbsp;</p>
              <h2 style={{ textAlign: "left" }}>
                <span style={{ color: "#999999" }}>
                  ВОЗВРАТ ИЛИ ОБМЕН ТОВАРА ДЛЯ СТРАН БЛИЖНЕГО ЗАРУБЕЖЬЯ (СНГ)
                </span>
              </h2>
              <p>
                &nbsp;
                <span style={{ color: "#000000" }}>
                  Вы можете обменять или вернуть купленный товар в течении 14
                  дней после получения посылки от почтовых служб, если сохранены
                  оригинальная упаковка, ярлыки, нет следов носки.
                </span>
              </p>
              <p>
                <span style={{ color: "#000000" }}>
                  Стоимость доставки товара надлежащего качества обратно на
                  склад интернет магазина&nbsp;покупатель оплачивает
                  самостоятельно. После получения пары и ее проверки, мы
                  осуществляем возврат денежных средств тем же способом, которым
                  вы производили оплату в срок от 3 до 30 дней.
                </span>
              </p>
              <p>
                <span style={{ color: "#000000" }}>
                  <strong>Адрес возврата для курьерской службы CDEK</strong>:
                </span>
              </p>
              <p>
                <span style={{ color: "#000000" }}>
                  <strong>Куда</strong>: г. Минск, ул. Притыцкого,77, пом. 147н
                  (пункт выдачи
                </span>{" "}
                <a
                  href="https://cdek.by/contacts/______________________________________________9302.html"
                  rel="nofollow"
                >
                  СДЭК
                </a>
                <span style={{ color: "#000000" }}>)</span>
              </p>
              <p>
                <span style={{ color: "#000000" }}>
                  <strong>Получатель</strong>: ИП Ермакович Д.B.
                </span>
              </p>
              <p>
                <br /> <br />
                <span style={{ color: "#000000" }}>
                  {" "}
                  Остались вопросы? Позвоните нам +375296737396
                </span>
              </p>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Returns;
