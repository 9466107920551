import React, { useEffect } from "react";
import Title from "../components/common/title";
import Slider from "../components/home/slider";
import NoveltyList from "../components/home/noveltyList";
import {
  fetchAllNoveltyProducts,
  fetchAllSaleProducts,
  fetchPhotosForMain,
} from "../asyncActions/catalog";
import { useDispatch, useSelector } from "react-redux";
import SelloutList from "../components/home/selloutList";
import InstagramList from "../components/home/instagramList";

import "../components/home/home.scss";
import PopularList from "../components/home/popularList";

const Home = () => {
  const dispatch = useDispatch();
  const saleProducts = useSelector((state) => state.products.saleProducts);
  const noveltyProducts = useSelector(
    (state) => state.products.noveltyProducts
  );

  useEffect(() => {
    dispatch(fetchAllNoveltyProducts());
    dispatch(fetchAllSaleProducts());
    dispatch(fetchPhotosForMain());
  }, []);

  return (
    <div style={{ flexDirection: "column" }}>
      <Title
        str={
          "Обувь в Минске | Купить дизайнерскую женскую обувь в интернет-магазине del-corso.by"
        }
      />
      <Slider />

      {noveltyProducts.length > 0 && <NoveltyList />}
      <PopularList />
      {saleProducts.length > 0 && <SelloutList />}

      <div className="s-line s-index-betters">
        <div className="container">
          <div className="w-index-betters">
            <div className="row"></div>
          </div>
        </div>
      </div>

      <InstagramList />
    </div>
  );
};

export default Home;
