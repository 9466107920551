import React from "react";
import MenuBar from "../../components/information/menuBar";

import "../../components/information/informations.scss";
import Title from "../../components/common/title";

const Delivery = () => {
  return (
    <div className={"information"}>
      <Title str={"Доставка и оплата"} />
      <div className={"product_info"}>
        <div style={{ padding: "0 15px 40px" }}>
          <MenuBar />
        </div>
        <div className={"product_info_description"}>
          <div className={"zx-6"}>
            <h1 className={"title"}>Доставка и оплата</h1>
            <article className={"bigger"}>
              <div className="page-delivery-navigate">
                <div className="page-delivery-navigate__description">
                  Навигация по странице:
                </div>
                <div className="page-delivery-navigate__container">
                  <div className="page-delivery-navigate__item">
                    <a
                      href="#delivery-4212283357"
                      className="page-delivery-navigation__link"
                      data-scroll=""
                    >
                      БЕСПЛАТНАЯ ДОСТАВКА ПО БЕЛАРУСИ
                    </a>
                  </div>
                  <div className="page-delivery-navigate__item">
                    <a
                      href="#delivery-3293418538"
                      className="page-delivery-navigation__link"
                      data-scroll=""
                    >
                      СКОЛЬКО ПАР ОБУВИ ВЫ МОЖЕТЕ ЗАКАЗАТЬ?
                    </a>
                  </div>
                  <div className="page-delivery-navigate__item">
                    <a
                      href="#delivery-1220365925"
                      className="page-delivery-navigation__link"
                      data-scroll=""
                    >
                      ПРИМЕРКА ПЕРЕД ПОКУПКОЙ
                    </a>
                  </div>
                  <div className="page-delivery-navigate__item">
                    <a
                      href="#delivery-1921489673"
                      className="page-delivery-navigation__link"
                      data-scroll=""
                    >
                      ДОСТАВКА В РОССИЮ И КАЗАХСТАН
                    </a>
                  </div>
                  <div className="page-delivery-navigate__item">
                    <a
                      href="#delivery-875325895"
                      className="page-delivery-navigation__link"
                      data-scroll=""
                    >
                      ДОСТАВКА ПО ВСЕМУ МИРУ
                    </a>
                  </div>
                </div>
              </div>
              <div className="page-deliver-content">
                <div className="page-deliver-content__item">
                  <a
                    id="delivery-4212283357"
                    className="page-deliver-content__anchor"
                  >
                    <h2 className="page-deliver-content__header">
                      БЕСПЛАТНАЯ ДОСТАВКА ПО БЕЛАРУСИ
                    </h2>
                  </a>
                  <p className={"zx-1"}>
                    <span className={"zx-2"} style={{ color: "#000000" }}>
                      По Беларуси у нас действует бесплатная доставка курьером с
                      примеркой.
                    </span>
                  </p>
                  <p className={"zx-1"}>
                    <span className={"zx-2"} style={{ color: "#000000" }}>
                      Доставка от одного до трех дней, в зависимости от
                      населенного пункта.
                    </span>
                  </p>
                  <p className={"zx-1"}>
                    <span className={"zx-2"} style={{ color: "#000000" }}>
                      При оформлении заказа до 18:00, доставка возможна на
                      следующий день.
                    </span>
                  </p>
                  <p className={"zx-1"}>
                    <span className={"zx-2"} style={{ color: "#000000" }}>
                      Дни доставки в ваш город можно посмотреть
                    </span>
                    &nbsp;
                    <a className={"zx-2"} href="/delivery-throughout-belarus">
                      здесь
                    </a>
                  </p>
                  <p className={"zx-1"}>
                    <span className={"zx-2"} style={{ color: "#000000" }}>
                      Если вы не смогли найти в списке ваш населенный пункт,
                      обращайтесь по номеру телефонa: +375(29)673 73 96,
                      +375(29)673 73 15
                    </span>
                  </p>
                  <p className={"zx-1"}>
                    <span className={"zx-2"} style={{ color: "#000000" }}>
                      Обращаем ваше внимание, что в случае небольшого процента
                      выкупа( до 75% процентов) может взиматься плата за
                      курьерские услуги.
                    </span>
                  </p>
                </div>

                <div className="page-deliver-content__item">
                  <a
                    id="delivery-3293418538"
                    className="page-deliver-content__anchor"
                  >
                    <h2 className="page-deliver-content__header">
                      СКОЛЬКО ПАР ОБУВИ ВЫ МОЖЕТЕ ЗАКАЗАТЬ?
                    </h2>
                  </a>
                  <p>
                    <span className={"zx-15"}>
                      Мы доставляем до двух пар либо размеров на&nbsp;выбор.
                    </span>
                  </p>
                </div>

                <div className="page-deliver-content__item">
                  <a
                    id="delivery-1220365925"
                    className="page-deliver-content__anchor"
                  >
                    <h2 className="page-deliver-content__header">
                      ПРИМЕРКА ПЕРЕД ПОКУПКОЙ
                    </h2>
                  </a>
                  <p>
                    Курьер за 30-40 минут до прибытия связывается по указанному
                    номеру телефона, доставляет товар до двери и предоставляет
                    до 15 минут на примерку.
                  </p>
                </div>

                <div className="page-deliver-content__item">
                  <a
                    id="delivery-1921489673"
                    className="page-deliver-content__anchor"
                  >
                    <h2 className="page-deliver-content__header">
                      ДОСТАВКА В РОССИЮ И КАЗАХСТАН
                    </h2>
                  </a>
                  <p className={"zx-1"}>
                    <span className={"zx-2"} style={{ color: "#000000" }}>
                      Осуществляется только по
                      <strong className={"zx-2"}>&nbsp;100%</strong>
                      &nbsp;предоплате.
                    </span>
                  </p>
                  <p className={"zx-1"}>
                    <span className={"zx-2"} style={{ color: "#000000" }}>
                      Доставку производит курьерская служба
                    </span>
                    &nbsp;
                    <a
                      className={"zx-2"}
                      href="https://cdek.ru/"
                      rel="nofollow"
                    >
                      <span className={"zx-24"}>СДЭК</span>
                    </a>
                    .
                  </p>
                  <p className={"zx-1"}>
                    <span className={"zx-2"} style={{ color: "#000000" }}>
                      Возможна доставка курьером до двери, либо до пункта выдачи
                      заказов
                    </span>
                    &nbsp;
                    <span className={"zx-24"}>СДЭК</span>.
                  </p>
                  <p className={"zx-1"}>
                    <span className={"zx-2"} style={{ color: "#000000" }}>
                      Стоимость и вид доставки рассчитывается индивидуально
                      согласно тарифам и срокам доставки курьерской службы.
                    </span>
                  </p>
                  <p className={"zx-1"}>
                    <span className={"zx-2"} style={{ color: "#000000" }}>
                      <strong className={"zx-2"}>ОПЛАТА ЗА</strong>&nbsp;
                      <strong className={"zx-2"}>ДОСТАВКУ</strong>
                      &nbsp;осуществляется при получении товара пластиковой
                      картой либо наличными.
                    </span>
                  </p>
                  <p className={"zx-1"}>
                    <span className={"zx-2"} style={{ color: "#000000" }}>
                      Сроки доставки от 2 до 7 дней.
                    </span>
                  </p>
                  <p className={"zx-1"}>
                    <span className={"zx-2"} style={{ color: "#000000" }}>
                      <strong className={"zx-2"}>
                        Перед оформлением заказа выберите нужный регион
                      </strong>
                      &nbsp;(Россия либо Другие, см. рисунок ниже)
                    </span>
                  </p>
                  <p className={"zx-1"}>
                    <img
                      className={"zx-18"}
                      src="https://marselshoes.by/storage/site-pages/November2020/%20%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%2011.jpg"
                      alt="Рис. 1"
                      width="873"
                      height="92"
                    />
                  </p>
                  <p className={"zx-1"}>
                    <span className={"zx-2"} style={{ color: "#000000" }}>
                      Оплата товара производится&nbsp;
                      <strong className={"zx-2"}>
                        банковской карточкой через интернет.
                      </strong>
                    </span>
                  </p>
                  <p className={"zx-1"}>
                    <span className={"zx-2"} style={{ color: "#000000" }}>
                      <span className={"zx-2"}>
                        <strong style={{ fontWeight: "bold" }}>
                          <span style={{ color: "#ff0000" }}>ВАЖНО!!!</span>
                          &nbsp; На данный момент оплата покупки для резидентов
                          РФ возможна только картами платежной системы&nbsp;
                          <span style={{ color: "#008000" }}>
                            <a
                              style={{ color: "#008000" }}
                              href="https://mironline.ru/"
                            >
                              МИР
                            </a>
                          </span>
                        </strong>
                      </span>
                    </span>
                  </p>
                  <p className={"zx-1"}>
                    <span className={"zx-2"} style={{ color: "#000000" }}>
                      После нажатия кнопки «Купить» вы перейдете на специальную
                      защищенную платежную страницу процессинговой системы
                    </span>
                    &nbsp;
                    <strong className={"zx-2"}>
                      <a
                        className={"zx-2"}
                        href="http://bepaid.by/"
                        rel="nofollow"
                      >
                        bePaid
                      </a>
                    </strong>
                    .
                  </p>
                  <p className={"zx-1"}>
                    <span className={"zx-2"} style={{ color: "#000000" }}>
                      На платежной странице будет указан номер заказа и сумма
                      платежа. Для оплаты вам необходимо ввести свои карточные
                      данные и подтвердить платеж, нажав кнопку «Оплатить».
                    </span>
                  </p>
                  <p className={"zx-1"}>&nbsp;</p>
                  <h2 className={"zx-21"}>
                    <strong className={"zx-22"}>
                      ПОСЛЕ ОФОРМЛЕНИЯ ЗАКАЗА, В РАБОЧЕЕ ВРЕМЯ ( С 10.00- 20.00
                      без выходных) С ВАМИ СВЯЖЕТСЯ НАШ МЕНЕДЖЕР.
                    </strong>
                  </h2>
                  <p>
                    <strong className={"zx-22"}>
                      <span className={"zx-23"}>
                        Остались вопросы? Позвоните нам +375296737396 или
                        написать в whatsapp/viber
                      </span>
                    </strong>
                  </p>
                </div>

                <div className="page-deliver-content__item">
                  <a
                    id="delivery-875325895"
                    className="page-deliver-content__anchor"
                  >
                    <h2 className="page-deliver-content__header">
                      ДОСТАВКА ПО ВСЕМУ МИРУ
                    </h2>
                  </a>
                  <p>
                    <span className={"zx-14"}>
                      Доставка в другие страны осуществляется международной
                      почтой{" "}
                      <a href="https://belpost.by/services/Pochtovyyeotpravleniya0/Peresylkatovarov/servicesPochtovyyeotpravl0">
                        EMS
                      </a>{" "}
                      только по 100% предоплате стоимости товара и стоимости
                      доставки.{" "}
                    </span>
                  </p>
                  <p>
                    <span className={"zx-14"}>
                      Стоимость доставки составляет 16 USD.{" "}
                    </span>
                  </p>
                  <p>
                    <span className={"zx-14"}>
                      Срок доставки от 5 до 25 дней
                    </span>
                  </p>
                  <p>
                    <strong className={"zx-19"}>
                      Перед оформлением заказа выберите регион "Другие"
                    </strong>
                    <span className={"zx-17"}>&nbsp;</span>
                    <span className={"zx-17"}>(см. рисунок ниже)</span>
                  </p>
                  <p className={"zx-1"}>
                    <img
                      className={"zx-18"}
                      src="https://marselshoes.by/storage/site-pages/November2020/%20%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%2011.jpg"
                      alt="Рис. 1"
                      width="873"
                      height="92"
                    />
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <span className={"zx-14"}>
                      Оплата товара производится банковской карточкой через
                      интернет. После нажатия кнопки «Купить» вы перейдете на
                      специальную защищенную платежную страницу процессинговой
                      системы{" "}
                    </span>
                    <strong className={"zx-16"}>
                      <a
                        className={"zx-3"}
                        href="http://bepaid.by/"
                        rel="nofollow"
                      >
                        bePaid.
                      </a>
                    </strong>
                  </p>
                  <p>
                    <span className={"zx-14"}>
                      На платежной странице будет указан номер заказа и сумма
                      платежа.{" "}
                    </span>
                  </p>
                  <p>
                    <span className={"zx-14"}>
                      Для оплаты вам необходимо ввести свои карточные данные и
                      подтвердить платеж, нажав кнопку «Оплатить».{" "}
                    </span>
                  </p>
                  <p>
                    <span className={"zx-14"}>
                      ПОСЛЕ ОФОРМЛЕНИЯ ЗАКАЗА, В РАБОЧЕЕ ВРЕМЯ ( С 10.00- 20.00
                      без выходных) С ВАМИ СВЯЖЕТСЯ НАШ МЕНЕДЖЕР.{" "}
                    </span>
                  </p>
                  <p>
                    <span className={"zx-14"}>
                      Остались вопросы? Позвоните нам +375296737396 или написать
                      в whatsapp/viber
                    </span>
                  </p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Delivery;
