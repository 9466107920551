import React from "react";
import "../components/contacts/contact.scss";
import ContactsItem from "../components/contacts/contactsItem";
import Title from "../components/common/title";

const mapCenter = "27.551657,53.903770"; // долгота, широта
const placemark = "27.551657,53.903770";
const zoomLevel = 16;

const linkToMap =
  "https://yandex.by/maps/org/del_corso/166944273370/?ll=27.565609%2C53.912003&mode=search&sctx=ZAAAAAgBEAAaKAoSCXmu78NBjjtAES%2F6CtKM80pAEhIJ6DBfXoB90T8RxXb3AN2Xwz8iBgABAgMEBSgKOABA9Z4GSAFqAnVhnQHNzEw9oAEAqAEAvQHgJ7qVwgEG2ued9e0E6gEA8gEA%2BAEAggIJZGVsLWNvcnNvigIAkgIAmgIMZGVza3RvcC1tYXBz&sll=27.565609%2C53.912003&sspn=0.067849%2C0.037995&text=del-corso&utm_source=ntp_chrome&z=14.01";
const addresses = [
  {
    id: 1,
    building: "ТЦ Немига-3",
    postalCode: "220030",
    addressLocality: "г. Минск",
    streetAddress: "ул. Немига 3, этаж 1, пав.1",
    phoneHref: "tel:+375297577876",
    phone: "+375 29 757-78-76",
    workTime: "работаем с 10:00 до 20:30",
  },
  // {
  //   id: 2,
  //   building: "ТЦ Дана Молл",
  //   postalCode: "220114",
  //   addressLocality: "г. Минск",
  //   streetAddress: "ул. Петра Мстиславца 11, 2 этаж пав. 2-30",
  //   phoneHref: "tel:+375296737325",
  //   phone: "+375 29 673-73-25",
  //   workTime: "работаем с 10:00 до 22:00",
  // },
  // {
  //   id: 3,
  //   building: "ТЦ Грин Сити",
  //   postalCode: "220121",
  //   addressLocality: "г. Минск",
  //   streetAddress: 'ул. Притыцкого 156, 2 этаж (ст. метро "Каменная Горка")',
  //   phoneHref: "tel:+375296737362",
  //   phone: "+375 29 673-73-62",
  //   workTime: "работаем с 10:00 до 20:30",
  // },
  // {
  //   id: 1,
  //   building: "",
  //   postalCode: "",
  //   addressLocality: "",
  //   streetAddress: "",
  //   phoneHref: "",
  //   phone: "",
  //   workTime: "",
  // },
];

const staticMapUrl = `https://static-maps.yandex.ru/1.x/?ll=${mapCenter}&size=600,400&z=${zoomLevel}&l=map&pt=${placemark},pm2dgl`;

const Contact = () => {
  return (
    <div className={"container"} style={{ margin: "65px auto 30px" }}>
      <Title str={"Контактная информация"} />
      <ContactsItem />

      <div className="row row-map-contacts ">
        <div className="col-lg-5">
          {/*<div className="section-name _h4 uppercase bold mb-20">*/}
          {/*  наши магазины:*/}
          {/*</div>*/}
          <div
            className="row"
            itemScope=""
            itemType="https://schema.org/Organization"
            style={{ marginLeft: "15px" }}
          >
            <h1 className="mb-10">Del-corso</h1>
            {/*<span itemProp="name" style={{ fontSize: "25px" }}>*/}
            {/*  */}
            {/*</span>*/}
            {addresses.map((address) => (
              <div className="col-lg-12 col-md-6 col-12">
                <div className="w-contacts-block">
                  <div
                    className="description adress"
                    itemProp="address"
                    itemScope=""
                    itemType="https://schema.org/PostalAddress"
                  >
                    {" "}
                    {/*&gt; */}
                    {address.building} <br />
                    <span itemProp="postalCode">{address.postalCode}</span>,
                    <span itemProp="addressLocality">
                      {address.addressLocality}
                    </span>
                    ,
                    <span itemProp="streetAddress">
                      {address.streetAddress}
                    </span>
                    <div className="phone">
                      <a
                        href={address.phoneHref}
                        className="phone__link"
                        itemProp="telephone"
                      >
                        {address.phone}
                      </a>
                    </div>{" "}
                    {address.workTime}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className="col-lg-7 pb-30"
          onClick={() => {
            window.open(linkToMap, "_blank");
          }}
        >
          <img src={staticMapUrl} alt="Static Map" />
        </div>
      </div>
    </div>
  );
};

export default Contact;
