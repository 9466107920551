import React from "react";
import MenuBar from "../../components/information/menuBar";

import "../../components/information/informations.scss";

import card1 from "./card1.jpg";
import card2 from "./card2.jpg";
import check from "./check.jpg";
import Title from "../../components/common/title";

const SecurePay = () => {
  return (
    <div className={"information"}>
      <Title str={"Безопасность и оплата"} />
      <div className={"product_info"}>
        <div style={{ padding: "0 15px 40px" }}>
          <MenuBar />
        </div>
        <div className={"product_info_description"}>
          <div className="zx-6">
            <h1 className={"title"}>Безопасность и оплата</h1>
            <article className={"bigger"}>
              <p className={"zx-1"}>
                <strong>
                  <span style={{ color: "#000000" }}>
                    Для граждан РФ и зарубежья
                  </span>
                </strong>
              </p>
              <p className={"zx-1"}>
                <span className={"zx-2"} style={{ color: "#000000" }}>
                  После нажатия кнопки «Купить» вы перейдете на специальную
                  защищенную платежную страницу процессинговой системы
                </span>
                &nbsp;
                <strong className={"zx-2"}>
                  <a className={"zx-2"} href="http://bepaid.by/" rel="nofollow">
                    bePaid
                  </a>
                </strong>
                .
              </p>
              <p className={"zx-1"}>
                <span className={"zx-2"} style={{ color: "#000000" }}>
                  На платежной странице будет указан номер заказа и сумма
                  платежа. Для оплаты вам необходимо ввести свои карточные
                  данные и подтвердить платеж, нажав кнопку «Оплатить».
                </span>
              </p>
              <p className={"zx-1"}>
                <span className={"zx-2"} style={{ color: "#000000" }}>
                  <img src={card1} alt="" width="232" height="197" />
                  &nbsp;
                  <img src={card2} alt="" width="228" height="196" />
                </span>
              </p>
              <p className={"zx-1"}>
                <span className={"zx-2"} style={{ color: "#000000" }}>
                  Если ваша карта поддерживает технологию 3-D Secure, системой
                  вам будет предложено пройти стандартную одноминутную процедуру
                  проверки владельца карты на странице вашего банка (банка,
                  который выдал вашу карту).
                </span>
              </p>
              <p className={"zx-1"}>
                <span className={"zx-2"} style={{ color: "#000000" }}>
                  <strong className={"zx-2"}>После оплаты</strong>
                  &nbsp;наш менеджер свяжется с вами для уточнения деталей по
                  доставке.
                </span>
              </p>
              <p className={"zx-1"}>
                <span className={"zx-2"} style={{ color: "#000000" }}>
                  Обращаем ваше внимание, что после проведения платежа на
                  указанный вами электронный адрес придет&nbsp;
                  <u className={"zx-2"}>подтверждение оплаты</u>. Просим вас
                  сохранять данные оплат.
                </span>
              </p>
              <p className={"zx-1"}>
                <span className={"zx-4"}>
                  Мы принимаем платежи по следующим банковским картам: Visa,
                  Visa Electron, MasterCard, Maestro, Белкарт.
                </span>
              </p>
              <p className={"zx-1"}>
                <span className={"zx-2"} style={{ color: "#000000" }}>
                  Платежи по банковским картам осуществляются через систему
                  электронных платежей
                </span>
                &nbsp;
                <strong className={"zx-2"}>
                  <a className={"zx-2"} href="http://bepaid.by/" rel="nofollow">
                    bePaid
                  </a>
                </strong>
                .&nbsp;
                <span className={"zx-2"} style={{ color: "#000000" }}>
                  Платежная страница системы bePaid отвечает всем требованиям
                  безопасности передачи данных (PCI DSS Level 1). Все
                  конфиденциальные данные хранятся в зашифрованном виде и
                  максимально устойчивы к взлому. Доступ к авторизационным
                  страницам осуществляется с использованием протокола,
                  обеспечивающего безопасную передачу данных в Интернетe
                  (SSL/TLS).
                </span>
              </p>
              <p className={"zx-1"}>
                <span className={"zx-2"} style={{ color: "#000000" }}>
                  Возврат денежных средств осуществляется на карту, с которой
                  ранее была произведена оплата. Срок поступления денежных
                  средств на карту от 3 до 30 дней с момента осуществления
                  возврата Продавцом.
                </span>
              </p>
              <p className={"zx-1"}>&nbsp;</p>
              <p className={"zx-1"}>
                <span style={{ color: "#000000" }}>
                  <strong>Для граждан РБ</strong>&nbsp;
                </span>
              </p>
              <p>
                <span className={"zx-5"}>
                  По доставке оплата производится только наличными курьеру после
                  примерки.
                </span>
              </p>
              <p>
                <span style={{ color: "#000000" }}>
                  Документами, подтверждающими факт приобретения товара в нашем
                  интернет-магазине является касовый чек:
                </span>
              </p>
              <p>
                <img src={check} alt="" width="133" height="100" />
              </p>
              <p>
                <span style={{ color: "#000000" }}>
                  Вместе с кассовым чеком также передается гарантийный талон и
                  пямятка по уходу за обувью.
                </span>
              </p>
              <p>&nbsp;</p>
              <p className={"zx-1"}>
                <span className={"zx-2"} style={{ color: "#000000" }}>
                  Условия возврата описаны здесь
                </span>
                &nbsp;
                <a
                  className={"zx-2"}
                  href="https://marselshoes.by/pages/returns"
                >
                  <strong className={"zx-2"}>обмен и возврат</strong>
                </a>
                .
              </p>
              <p>&nbsp;</p>
              <p className={"zx-1"}>
                <span className={"zx-2"} style={{ color: "#000000" }}>
                  Остались вопросы? Позвоните нам +375296737396 или написать в
                  whatsapp/viber
                </span>
              </p>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurePay;
