import React from "react";

import it from "./svg/inst.svg";
import tg from "./svg/telegram.svg";
import vk from "./svg/vk.svg";

import ContactInfoUnit from "./contactInfoUnit";
import CompanyInfoUnit from "./companyInfoUnit";
import ClientInfoUnit from "./clientInfoUnit";

import "./footer.scss";
const DEFAULT_CLASSNAME = "footer";

export const Footer = () => {
  return (
    <div className={DEFAULT_CLASSNAME}>
      <div className={`container`} style={{ marginTop: "0px" }}>
        <div className={`${DEFAULT_CLASSNAME}_content`}>
          <div className={`${DEFAULT_CLASSNAME}_navigation`}>
            <ContactInfoUnit />
            <CompanyInfoUnit />
            <ClientInfoUnit />
          </div>
        </div>
        <hr />
        <div
          className={`${DEFAULT_CLASSNAME}_content`}
          style={{ marginTop: "10px" }}
        >
          <div className={`${DEFAULT_CLASSNAME}_navigation`}>
            <div className={`${DEFAULT_CLASSNAME}_navigation_item`}>
              <div
                className={`${DEFAULT_CLASSNAME}_navigation_sub-item_logo`}
                style={{ fontSize: 14, width: 150 }}
              >
                {"Del-corso © 2023"}
              </div>
            </div>
            <div className={`${DEFAULT_CLASSNAME}_navigation_item`}>
              <div
                className={`${DEFAULT_CLASSNAME}_navigation_sub-item`}
                style={{ fontSize: 12 }}
              >
                {`Зарегистрирован в торговом реестре Республики Беларусь Администрацией Октябрьского района г. Минска 03.02.2020 г. Регистрационный номер 472576 .`}
              </div>
            </div>
            <div className={`${DEFAULT_CLASSNAME}_navigation_item`}>
              <div
                className={`${DEFAULT_CLASSNAME}_navigation_sub-item`}
                style={{ fontSize: 12 }}
              >
                {`Интернет-магазин - Del-corso.by ИП Ермакович Дмитрий Владимирович. г. Минск ул. Казинца 39-10. Свидетельство о государственной регистрации 193156252 Мингорисполкомом 23.10.2018 `}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*<div className={`${DEFAULT_CLASSNAME}_footerContent`}>*/}
      {/*  <div className={`${DEFAULT_CLASSNAME}_links`}>*/}
      {/*    <a*/}
      {/*      rel={"nofollow"}*/}
      {/*      href={"https://t.me/DreamStore_by"}*/}
      {/*      target={"_blank"}*/}
      {/*      className={`${DEFAULT_CLASSNAME}_links_item`}*/}
      {/*    >*/}
      {/*      <img alt={"icon"} src={tg} />*/}
      {/*    </a>*/}
      {/*    <a*/}
      {/*      rel={"nofollow"}*/}
      {/*      href={"https://instagram.com/dreamstore_by"}*/}
      {/*      target={"_blank"}*/}
      {/*      className={`${DEFAULT_CLASSNAME}_links_item`}*/}
      {/*    >*/}
      {/*      <img alt={"icon"} src={it} />*/}
      {/*    </a>*/}
      {/*    <a*/}
      {/*      rel={"nofollow"}*/}
      {/*      href={"viber://chat?number=%2B375291553020"}*/}
      {/*      className={`${DEFAULT_CLASSNAME}_links_item`}*/}
      {/*    >*/}
      {/*      <img style={{ marginBottom: "2px" }} alt={"icon"} src={vk} />*/}
      {/*    </a>*/}
      {/*  </div>*/}
      {/*  <div className={`${DEFAULT_CLASSNAME}_text`}>*/}
      {/*    {"Del-corso © 2023"}*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};
