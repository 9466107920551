import React from "react";
import { Helmet } from "react-helmet";

const Title = ({ str }) => {
  return (
    <Helmet>
      <title>{str}</title>
    </Helmet>
  );
};

export default Title;
