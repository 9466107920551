import React from "react";
import MenuBar from "../../components/information/menuBar";

import "../../components/information/informations.scss";
import Title from "../../components/common/title";

const Offer = () => {
  return (
    <div className={"information"}>
      <Title str={"Оферта"} />
      <div className={"product_info"}>
        <div style={{ padding: "0 15px 40px" }}>
          <MenuBar />
        </div>
        <div className={"product_info_description"}>
          <div className={"zx-6"}>
            <h1 className={"title"}>Оферта</h1>
            <article className={"bigger"}>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
              </p>
              <p className="MsoNormal">
                1.1 В настоящей оферте, если контекст не требует иного,
                нижеприведенные термины имеют следующие значения и являются ее
                составной неотъемлемой частью:
              </p>
              <p className="MsoNormal">
                Продавец — Индивидуальный предприниматель Ермакович Дмитрий
                Владимирович.
              </p>
              <p className="MsoNormal">
                Покупатель — любое дееспособное физическое лицо, акцептировавшее
                публичную оферту на условиях настоящей оферты, размещающее
                заказы и приобретающее товары у ИП Ермакович Д.В, которые
                представлены на сайте{" "}
                <span lang="EN-US" className={"zx-9"}>
                  Marselshoes
                </span>
                .
                <span lang="EN-US" className={"zx-9"}>
                  by
                </span>
                , для своих личных, бытовых и других нужд, не связанных с
                осуществлением предпринимательской деятельности.
              </p>
              <p className="MsoNormal">
                Интернет-магазин — официальный интернет-магазин Продавца «
                <span lang="EN-US" className={"zx-9"}>
                  Marselshoes
                </span>
                », расположенный по интернет адресу https://www.
                <span lang="EN-US" className={"zx-9"}>
                  Marselshoes
                </span>
                .by. В рамках настоящего договора, понятия Интернет-магазин и
                Магазин, а также интернет адреса https://www.
                <span lang="EN-US" className={"zx-9"}>
                  Marselshoes
                </span>
                .by и производные от Marselshoes.by являются равносильными и
                трактуются аутентично, по контексту оферты.
              </p>
              <p className="MsoNormal">
                Товар — объект соглашения сторон, перечень наименований
                ассортимента, представленный в официальном интернет-магазине.
              </p>
              <p className="MsoNormal">
                Акционная скидка — это скидка, которая предоставляется на
                определенный Товар. Размер скидки указывается на странице
                Товара. Цена на Товар указана с учетом акционной скидки.
              </p>
              <p className="MsoNormal">
                Скидка по промокоду — это скидка, которая предоставляется на
                определенную группу Товаров при введении специального кода при
                оформлении Заказа в «Корзине» Покупателя.
              </p>
              <p className="MsoNormal">
                Промокод — это специальный код, который предоставляет скидку на
                определенную группу Товаров и имеет срок действия. Для получения
                скидки по промокоду, промокод необходимо ввести в специальное
                поле в «Корзине» Покупателя при оформлении Заказа, некоторые
                промокоды могут быть применены автоматически. К одной единице
                товара, может быть применен только один промокод.
              </p>
              <p className="MsoNormal">
                Скидка постоянного Покупателя — это скидка, предоставляемая
                интернет-магазином Покупателю на определенных условиях. Условия
                предоставления скидки предоставлены в разделе «Моя Скидка»
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                2. ОБЩИЕ ПОЛОЖЕНИЯ
              </p>
              <p className="MsoNormal">
                2.1 Настоящая публичная Оферта (в дальнейшем — Договор является
                официальным предложением ИП Ермакович Д.В в адрес любого
                физического лица, обладающего дееспособностью и необходимым
                полномочием заключить с ИП Ермакович Д.В договор купли-продажи
                Товара на условиях, определенных в настоящей оферте и содержит
                все существенные условия договора.
              </p>
              <p className="MsoNormal">
                2.2 Отношения в области защиты прав потребителей регулируются
                законодательством Республики Беларусь, в частности положениями
                Гражданского кодекса РБ, Правилами осуществления розничной
                торговли отдельными видами товаров и общественного питания,
                Законом РБ о защите прав потребителей, Законом РБ об обращениях
                граждан и юридических лиц, Правилами продажи товаров по
                образцам.
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                3. ПРЕДМЕТ ТОВАРА И ЦЕНА ТОВАРА
              </p>
              <p className="MsoNormal">
                3.1 Продавец передает, а Покупатель принимает и оплачивает
                товары на условиях настоящего
                <span className={"zx-10"}>&nbsp;&nbsp; </span>Договора.
              </p>
              <p className="MsoNormal">
                3.2 Право собственности на Заказанные Товары переходит к
                Покупателю с момента фактической передачи Товара Покупателю и
                оплаты последним полной стоимости Товара. Риск его случайной
                гибели или повреждения Товара переходит к Покупателю с момента
                фактической передачи Товара Покупателю.
              </p>
              <p className="MsoNormal">
                3.3 Цены на Товар определяются Продавцом в одностороннем
                бесспорном порядке и указываются на страницах интернет-магазина,
                расположенного по интернет-адресу:{" "}
                <a href="/">https://www.Marselshoes.by</a>.
              </p>
              <p className="MsoNormal">
                3.4 Цена товара указывается в белорусских рублях и включает в
                себя налог на добавленную стоимость.
              </p>
              <p className="MsoNormal">
                3.5 Окончательная Цена Товара определяется последовательным
                действием на Цену Товара скидок по следующему порядку:
              </p>
              <p className="MsoNormal">• Акционная скидка</p>
              <p className="MsoNormal">• Скидка по промокоду</p>
              <p className="MsoNormal">• Скидка постоянного Покупателя</p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                4. МОМЕНТ ЗАКЛЮЧЕНИЯ ДОГОВОРА
              </p>
              <p className="MsoNormal">
                4.1 Текст данного Договора является публичной офертой (в
                соответствии со статьёй 405 и статьёй 407 Гражданского кодекса
                РБ).
              </p>
              <p className="MsoNormal">
                4.2 Акцептом настоящей оферты (договора) — признается оформление
                Покупателем заказа на Товар в соответствии с условиями настоящей
                оферты.
              </p>
              <p className="MsoNormal">
                4.3 Оформление Покупателем заказа на Товар производится путем
                ввода личных данных разделе «корзина» и нажатием кнопки
                заказать.
              </p>
              <p className="MsoNormal">
                4.4 Договор, заключаемый на основании акцептирования Покупателем
                настоящей оферты является договором присоединения, к которому
                Покупатель присоединяется без каких-либо исключений и/или
                оговорок.
              </p>
              <p className="MsoNormal">
                4.5 Факт оформления заказа Покупателем является безоговорочным
                фактом принятия Покупателем условий данного Договора.
                Покупатель, приобретший товар в Интернет-магазине Продавца
                (оформивший заказ товара), рассматривается как лицо, вступившее
                с Продавцом в отношения на условиях настоящего Договора.
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                5. ПРАВА И ОБЯЗАННОСТИ СТОРОН
              </p>
              <p className="MsoNormal">5.1.1. Продавец обязуется:</p>
              <p className="MsoNormal">
                С момента заключения настоящего Договора обеспечить в полной
                мере все обязательства перед Покупателем в соответствии с
                условиями настоящего договора и действующего законодательства.
                Продавец оставляет за собой право невыполнения обязательств по
                Договору в случае возникновения обстоятельств непреодолимой
                силы, указанных в п.6 настоящего Договора.
              </p>
              <p className="MsoNormal">
                Обрабатывать персональные данные Покупателя и обеспечивать их
                конфиденциальность в порядке, установленном действующим
                законодательством Республики Беларусь.
              </p>
              <p className="MsoNormal">
                5.1.2 Акцептируя настоящую оферту, Покупатель выражает согласие
                и разрешает ИП Ермакович Д.В г.Минск ул.Казинца, дом 39,кв 10,
                Республика Беларусь (далее — Оператор) обрабатывать свои
                персональные данные, в том числе фамилию, имя, отчество, дата
                рождения, пол, биометрические персональные данные, место работы
                и должность, почтовый адрес; домашний, рабочий, мобильный
                телефоны, адрес электронной почты, включая сбор, систематизацию,
                накопление, хранение, уточнение (обновление, изменение),
                использование, распространение (в том числе передачу на
                территории Республики Беларусь и трансграничную передачу),
                обезличивание, блокирование, уничтожение персональных данных, а
                также передачу их контрагентам Оператора для проведения
                исследований, направленных на улучшение качества услуг, для
                проведения маркетинговых программ, статистических исследований,
                а также для продвижения услуг на рынке путем осуществления
                прямых контактов с Покупателем с помощью различных средств
                связи, включая, но, не ограничиваясь: почтовая рассылка,
                электронная почта, телефон, факсимильная связь, сеть Интернет.
                Покупатель выражает согласие и разрешает Оператору и
                контрагентам Оператора обрабатывать персональные данные
                Покупателя, с помощью автоматизированных систем управления
                базами данных, а также иных программных средств, специально
                разработанных по поручению Оператора. Работа с такими системами
                осуществляется по предписанному оператором алгоритму (сбор,
                систематизация, накопление, хранение, уточнение, использование,
                блокирование, уничтожение). Используемые способы обработки
                (включая, но, не ограничиваясь): автоматическая сверка почтовых
                кодов с базой кодов, автоматическая проверка написания названий
                улиц/населенных пунктов, уточнение данных с Покупателем путем
                телефонной, почтовой связи с Покупателем или с помощью контакта
                через сеть Интернет, сегментация базы по заданным критериям.
                Покупатель соглашается с тем, что, если это необходимо для
                реализации целей, указанных в настоящей оферте, его персональные
                данные, полученные Оператором, могут быть переданы третьим
                лицам, которым Оператор может поручить обработку персональных
                данных Покупателя на основании договора, заключенного с такими
                лицами, при условии соблюдения требований Закона Республики
                Беларусь «Об информации, информатизации и защите информации» и
                обеспечение такими третьими лицами конфиденциальности
                персональных данных и безопасности персональных данных при их
                обработке. При передаче указанных данных Покупателя Оператор
                предупреждает лиц, получающих персональные данные Покупателя, о
                том, что эти данные являются конфиденциальными и могут быть
                использованы лишь в целях, для которых они сообщены, и требуют
                от этих лиц соблюдения этого правила. Покупатель вправе
                запросить у Оператора полную информацию о своих персональных
                данных, их обработке и использовании, а также потребовать
                исключения или исправления/дополнения неверных или неполных
                персональных данных, отправив соответствующий письменный запрос
                на имя Оператора на почтовый адрес. Данное Покупателем согласие
                на обработку его персональных данных является бессрочным и может
                быть отозвано посредством направления Покупателем письменного
                заявления в адрес Оператора на почтовый адрес.
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                5.2 ПРОДАВЕЦ ИМЕЕТ ПРАВО
              </p>
              <p className="MsoNormal">
                5.2.1 Изменять настоящий Договор, Цены на Товар и Тарифы на
                сопутствующие услуги, способы и сроки оплаты и доставки товара в
                одностороннем порядке, помещая их на страницах
                интернет-магазина, расположенного по интернет-адресу:
                https://www.
                <span lang="EN-US" className={"zx-9"}>
                  Marselshoes
                </span>
                .by. Все изменения вступают в силу немедленно после публикации,
                и считаются доведенными до сведения Покупателя с момента такой
                публикации.
              </p>
              <p className="MsoNormal">
                5.2.2 Осуществлять записи телефонных переговоров с Покупателем.
                В соответствии с Законом Республики Беларусь «Об информации,
                информатизации и защите информации» Продавец обязуется:
                предотвращать попытки несанкционированного доступа к информации
                и/или передачу ее лицам, не имеющим непосредственного отношения
                к исполнению Заказов; своевременно обнаруживать и пресекать
                такие факты.
              </p>
              <p className="MsoNormal">
                5.2.3 Без согласования с Покупателем, передавать свои права и
                обязанности по исполнению Договора третьим лицам.
              </p>
              <p className="MsoNormal">
                5.2.4 Перед поставкой заказанного Клиентом товара Продавец имеет
                право потребовать от Клиента 100% предоплаты заказанного товара.
                Продавец имеет право отказать Клиенту в доставке товара при
                отсутствии такой оплаты.
              </p>
              <p className="MsoNormal">
                5.2.5 Продавец вправе не производить доставку Товара Клиенту
                курьером, в том числе без 100% предоплаты заказанного Товара, по
                адресу, указанному при оформлении Клиентом заказа, и передать
                Товар в согласованном с Клиентом фирменном пункте самовывоза.
              </p>
              <p className="MsoNormal">
                5.2.6 Продавец вправе произвести ограничения одновременно
                доставляемого Клиенту Товара.
              </p>
              <p className="MsoNormal">
                5.2.7 Использовать технологию «„cookies“». «„Cookies“» не
                содержат конфиденциальную информацию и не передаются третьим
                лицам.
              </p>
              <p className="MsoNormal">
                5.2.8 Получать информацию об ip — адресе посетителя Сайта{" "}
                <span lang="EN-US" className={"zx-9"}>
                  Marselshoes
                </span>
                .by. Данная информация не используется для установления личности
                посетителя и не подлежит передаче третьим лицам.
              </p>
              <p className="MsoNormal">
                5.2.9 Продавец вправе направлять Покупателю сообщения
                рекламно-информационного характера посредством e-mail и
                sms-рассылок с информацией о скидках, акциях, новых поступлениях
                и т.п. Частота рассылок определяется Продавцом самостоятельно, в
                одностороннем порядке.
              </p>
              <p className="MsoNormal">
                5.2.10 Поставщик вправе в одностороннем порядке отменить Заказ
                Покупателя в случае нарушения Покупателем п.5.3 настоящей
                Оферты.
              </p>
              <p className="MsoNormal">
                5.2.11 Продавец вправе произвести ограничения по сумме
                заказываемых товаров на один день доставки.
              </p>
              <p className="MsoNormal">
                5.2.12 Продавец вправе изменять условия Акций, размещённых на
                сайте интернет-магазина www.
                <span lang="EN-US" className={"zx-9"}>
                  Marselshoes
                </span>
                .by, в одностороннем порядке без согласия Покупателя, путём
                размещения таких изменений на сайте интернет-магазина www.
                <span lang="EN-US" className={"zx-9"}>
                  Marselshoes
                </span>
                .by.
              </p>
              <p className="MsoNormal">
                5.2.13 Размещая отзыв на сайте, Вы даёте согласие на
                использование данных отзыва на сторонних ресурсах.
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                5.3. ПОКУПАТЕЛЬ ОБЯЗУЕТСЯ
              </p>
              <p className="MsoNormal">
                5.3.1 До момента заключения Договора ознакомиться с содержанием
                и условиями Договора, ценами на Товар, предлагаемыми Продавцом в
                интернет-магазине.
              </p>
              <p className="MsoNormal">
                5.3.2 Во исполнение Продавцом своих обязательств перед
                Покупателем последний должен сообщить все необходимые данные,
                однозначно идентифицирующие его как покупателя, и достаточные
                для доставки Покупателю оплаченного им Товара.
              </p>
              <p className="MsoNormal">
                5.3.3 Оплатить заказанный Товар и, при необходимости доставки,
                оплатить доставку Товара, на условиях настоящего договора.
              </p>
              <p className="MsoNormal">
                5.3.4 Если Покупатель при оформлении Заказа указывает
                безналичный способ оплаты Заказа (банковской картой либо
                системой электронных платежей), то Покупатель обязан оплатить
                свой Заказ в течение 12 часов с момента оформления.
              </p>
              <p className="MsoNormal">5.3.5 Соблюдать Правила продажи.</p>
              <p className="MsoNormal">
                Не использовать товар, заказанный на интернет-сайте в
                предпринимательских целях.
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                5.4. ПОКУПАТЕЛЬ ИМЕЕТ ПРАВО
              </p>
              <p className="MsoNormal">
                5.4.1 Покупатель вправе отказаться от получения e-mail рассылки,
                для этого ему нужно нажать на ссылку в письме «Отменить подписку
                на эти сообщения…»
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                6. ДОСТАВКА ТОВАРА
              </p>
              <p className="MsoNormal">
                6.1 Доставка Товара Покупателю осуществляется в сроки,
                согласованные Сторонами при подтверждении заказа сотрудником
                Продавца и условиях, изложенных в Правилах продажи. Если
                покупатель не получает заказ в согласованный срок, продавец
                вправе аннулировать заказ без дополнительного информирования
                Клиента. В случае если заказ предоплачен денежные средства
                возвращаются покупателю в установленный законодательством срок.
              </p>
              <p className="MsoNormal">
                6.2 Продавец вправе ограничить количество одновременно
                доставляемого Клиенту Товара.
              </p>
              <p className="MsoNormal">
                6.3 После получения Заказа претензии к количеству, комплектности
                и виду Товара не принимаются.
              </p>
              <p className="MsoNormal">
                6.4 Проверка и примерка заказанного Товара, как и все
                взаиморасчеты с Курьером производятся не более, чем за 15 минут.
                Проверка и примерка заказанного Товара в пунктах самовывоза
                неограниченна по времени.
              </p>
              <p className="MsoNormal">
                6.5 Информацию о платной доставке Вы можете узнать при
                оформлении заказа.
              </p>
              <p className="MsoNormal">
                6.6 В случае отмены предоплаченного заказа Клиентом, после
                передачи его в доставку денежные средства будут возвращены в
                полном объеме
              </p>
              <p className="MsoNormal">
                6.7 При доставке предоплаченного заказа курьер или сотрудник
                пункта самовывоза может попросить Клиента предъявить документ
                удостоверяющий личность.
              </p>
              <p className="MsoNormal">
                6.8 При отказе Потребителя от Товара Продавец должен возвратить
                ему сумму, уплаченную Потребителем за исключением расходов
                Продавца на доставку от покупателя и/или к покупателю
                возвращенного товара, не позднее чем через 7 дней с даты
                предъявления Потребителем соответствующего требования.
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                7. ВОЗВРАТ ТОВАРА И ДЕНЕЖНЫХ СРЕДСТВ
              </p>
              <p className="MsoNormal">
                7.1 Возврат Товара осуществляется в соответствии с условиями и
                правилами, указанными в разделе «обмен и возврат»
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                8. ФОРС-МАЖОР
              </p>
              <p className="MsoNormal">
                <span className={"zx-7"}>
                  8.1{" "}
                  <span style={{ background: "white" }}>
                    Любая из&nbsp;Сторон освобождается от&nbsp;ответственности
                    за&nbsp;полное или частичное неисполнение своих обязательств
                    по&nbsp;настоящему Договору, если это неисполнение было
                    вызвано обстоятельствами непреодолимой силы, возникшими
                    после подписания настоящего Договора. «Обстоятельства
                    Непреодолимой Силы» означают чрезвычайные события или
                    обстоятельства, которые такая Сторона не&nbsp;могла
                    предвидеть или предотвратить доступными ей&nbsp;средствами.
                    Такие чрезвычайные события или обстоятельства включают
                    в&nbsp;себя, в&nbsp;частности: забастовки, наводнения,
                    пожары, землетрясения и&nbsp;иные стихийные бедствия, войны,
                    военные действия, действия белорусских или иностранных
                    государственных органов, а&nbsp;также любые иные
                    обстоятельства, выходящие за&nbsp;пределы разумного контроля
                    любой из&nbsp;Сторон. Изменения действующего
                    законодательства или нормативных актов, прямо или косвенно
                    влияющие на&nbsp;какую-либо из&nbsp;Сторон,
                    не&nbsp;рассматриваются как Обстоятельства непреодолимой
                    силы, однако, в&nbsp;случае внесения таких изменений,
                    которые не&nbsp;позволяют любой из&nbsp;Сторон исполнить
                    какие-либо из&nbsp;ее&nbsp;обязательств по&nbsp;настоящему
                    Договору, Стороны обязаны незамедлительно принять решение
                    относительно порядка работы по&nbsp;устранению этой проблемы
                    с&nbsp;тем, чтобы обеспечить Сторонам продолжение исполнения
                    настоящего Договора.
                  </span>
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                <span className={"zx-7"} style={{ background: "white" }}>
                  9. ОТВЕТСВЕННОСТЬ СТОРОН
                </span>
              </p>
              <p className="MsoNormal">
                <span className={"zx-7"} style={{ background: "white" }}>
                  9.1
                </span>
                <span className={"zx-7"}>
                  {" "}
                  <span style={{ background: "white" }}>
                    За неисполнение или ненадлежащее исполнение условий
                    настоящего Договора Стороны несут ответственность в
                    соответствии с законодательством Республики Беларусь.
                  </span>
                </span>
              </p>
              <p className="MsoNormal">
                <span className={"zx-7"} style={{ background: "white" }}>
                  9.2 Вся текстовая информация и графические изображения,
                  размещенные в интернет-магазине имеют законного
                  правообладателя, незаконное использование указанной информации
                  и изображений преследуется в соответствии с действующим
                  законодательством Республики Беларусь.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ textAlign: "center" }}
                align="center"
              >
                <span className={"zx-7"} style={{ background: "white" }}>
                  10.
                </span>
                <span className={"zx-7"}>
                  {" "}
                  <span style={{ background: "white" }}>ПРОЧИЕ УСЛОВИЯ</span>
                </span>
              </p>
              <p className="MsoNormal">
                <span className={"zx-7"} style={{ background: "white" }}>
                  10.1 Все споры, связанные с неисполнением, или ненадлежащим
                  исполнением своих обязательств по настоящему договору стороны
                  будут стараться решить в ходе переговоров.
                </span>
              </p>
              <p className="MsoNormal">
                <span className={"zx-7"} style={{ background: "white" }}>
                  10.2 В случае не достижения согласия в ходе переговоров, споры
                  будут разрешаться в судебном порядке в соответствии с
                  действующим законодательством Республики Беларусь.
                </span>
              </p>
              <p className="MsoNormal">
                <span className={"zx-7"} style={{ background: "white" }}>
                  10.3 Признание судом недействительным одного или несколько
                  положений настоящей Публичной оферты не влечет
                  недействительность других положений и публичной оферты в
                  целом.
                </span>
              </p>
              <p className="MsoNormal">
                <span className={"zx-8"}>&nbsp;</span>
              </p>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offer;
