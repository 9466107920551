import React, { useEffect, useState } from "react";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { PiCaretLeftThin, PiCaretRightThin } from "react-icons/pi";
import FavoriteStar from "../catalog/favoriteStar";

import "./imageItem.scss";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import noPhoto from "./nophoto.png";
import { REACT_APP_API_URL } from "../../index";

const ImageItem = () => {
  let product = useSelector((state) => state.products.product);
  const images = [];
  product.image_paths.map((image) => {
    images.push(`${REACT_APP_API_URL}product_images/${image}`);
  });
  if (images.length === 0) {
    images.push(`${noPhoto}`);
  }
  const [selectedImage, setSelectedImage] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [startIndex, setStartIndex] = useState(0);

  const { id } = useParams();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const totalImages = images.length;
  const numVisibleThumbnails =
    windowWidth > 990 ? (totalImages > 4 ? 4 : totalImages) : 1;

  const handleThumbnailClick = (index) => {
    setSelectedImage(index);
  };

  const handleArrowClick = (direction) => {
    setSelectedImage((prev) => {
      if (direction === "prev") {
        return (prev - 1 + totalImages) % totalImages;
      } else {
        return (prev + 1) % totalImages;
      }
    });
  };

  const generateThumbnailIndexes = () => {
    const indexes = [];
    for (let i = 0; i < numVisibleThumbnails; i++) {
      const index = (startIndex + i) % totalImages;
      indexes.push(index);
    }
    return indexes;
  };

  return (
    <div className="image-slider-container">
      <div className={"image-with-bar"}>
        {windowWidth > 990 && (
          <div style={{ flex: "0 0 18%" }}>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                let newStartIndex = startIndex - 1;
                if (newStartIndex === -1) {
                  newStartIndex = totalImages - 1;
                }
                setStartIndex(newStartIndex);
              }}
            >
              <div
                className={"arrow-tb"}
                style={{
                  marginTop: "2px",
                  marginBottom: "2px",
                }}
              >
                <BsArrowUp />
              </div>
            </div>
            <div className="thumbnail-bar">
              {generateThumbnailIndexes().map((index) => (
                <div
                  key={index}
                  className={`thumbnail ${
                    index === selectedImage ? "selected" : ""
                  }`}
                  onClick={() => handleThumbnailClick(index)}
                >
                  <img src={images[index]} alt={`Thumbnail ${index + 1}`} />
                </div>
              ))}
            </div>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setStartIndex((startIndex + 1) % totalImages);
              }}
            >
              <div
                className={"arrow-tb"}
                style={{
                  marginTop: "10px",
                }}
              >
                <BsArrowDown />
              </div>
            </div>
          </div>
        )}
        <div className={"main-image-div"}>
          <div className="main-image">
            <div className="image-container">
              <img src={images[selectedImage]} alt="Your Image" />
              <FavoriteStar productId={Number(id)} />
              <div
                className={"arrow-lr"}
                style={{ left: 0 }}
                onClick={() => handleArrowClick("prev")}
              >
                <PiCaretLeftThin />
              </div>
              <div
                className={"arrow-lr"}
                style={{ right: 0 }}
                onClick={() => handleArrowClick("next")}
              >
                <PiCaretRightThin />
              </div>
            </div>
          </div>
        </div>
      </div>

      {!(windowWidth > 990) && (
        <div className="dots">
          {images.map((_, index) => (
            <div
              key={index}
              className={`dot ${index === selectedImage ? "active" : ""}`}
              onClick={() => handleThumbnailClick(index)}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageItem;
