import React, { useEffect } from "react";
import { CATALOG_ROUTE, HOME_ROUTE } from "../../routes";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { deleteIdFavoriteProductAction } from "../../store/catalogReducer";
import { useNavigate } from "react-router-dom";

import noPhoto from "./nophoto.png";
import { REACT_APP_API_URL } from "../../index";

const FavoriteItem = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const idsFavoriteProducts = useSelector(
    (state) => state.products.idsFavoriteProducts
  );
  useEffect(() => {
    if (idsFavoriteProducts.length > 0) {
      const myString = idsFavoriteProducts.join(".");
      Cookies.set("favoriteProductId", myString);
    } else {
      Cookies.remove("favoriteProductId");
      navigate(HOME_ROUTE);
    }
  }, [idsFavoriteProducts]);

  const deleteFavorite = () => {
    dispatch(deleteIdFavoriteProductAction(product.id));
  };

  const images = [];
  product.image_paths.map((image) => {
    images.push(`${REACT_APP_API_URL}product_images/${image}`);
  });

  if (images.length === 0) {
    images.push(noPhoto);
    images.push(noPhoto);
  } else {
    if (images.length === 1) {
      images.push(images[0]);
    }
  }
  console.log(images);

  return (
    <div className="w-cart-table-item">
      <div className="row row-cart-table">
        <div className="col col-image">
          <a
            href={`${CATALOG_ROUTE}/${product.type_category.name}/item/${product.id}`}
            className="block__link"
          >
            <div className="w-image">
              <div className="r-box">
                <img
                  className="r-img contain default"
                  loading="lazy"
                  src={images[0]}
                  title={product.name}
                  alt={product.name}
                />
                <img
                  className="r-img contain hovered"
                  loading="lazy"
                  src={images[1]}
                  title={product.name}
                  alt={product.name}
                />
              </div>
            </div>
          </a>
        </div>
        <div className="col col-content">
          <div className="row row-content align-items-center">
            <div className="col col-name">
              <div className="w-name">
                <a
                  href={`${CATALOG_ROUTE}/${product.type_category.name}/item/${product.id}`}
                  className="name__link"
                >
                  {product.name}
                </a>
              </div>
              <div className="art">{`Артикул: ${product.vendor_code}`}</div>
            </div>
            <div className="col col-features">
              <div className="row">
                <div className="col col-size">
                  <div className="mobile-helper">{`Размер`}</div>
                </div>
                <div className="col col-color">
                  <div className="mobile-helper">{`Цвет`}</div>
                  {product.color.color}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col col-price">
          <div className="row row-price">
            <div className="col col-price-old">
              <div className="old price">
                <span className="number">{`${product.price} руб.`}</span>
              </div>
            </div>
            <div className="col col-price-middle">
              <div className="new price middle">{`${
                product.discount.discount_price
                  ? product.discount.discount_price
                  : product.price
              } руб.`}</div>
            </div>
            <div className="col col-price-controlls">
              <div className="w-controlls-buttons">
                <div className="row justify-content-end">
                  <div className="col-auto col-fav"></div>
                  <div className="col-auto col-del">
                    <a
                      onClick={deleteFavorite}
                      style={{ cursor: "pointer" }}
                      className="controll__link del"
                      data-item-id="1014"
                    >
                      Удалить
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoriteItem;
