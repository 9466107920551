import React from "react";
import MenuBar from "../../components/information/menuBar";

import "../../components/information/informations.scss";
import Title from "../../components/common/title";

const AboutUs = () => {
  return (
    <div className={"information"}>
      <Title str={"О нас"} />
      <div className={"product_info"}>
        <div style={{ padding: "0 15px 40px" }}>
          <MenuBar />
        </div>
        <div className={"product_info_description"}>
          <div className={"zx-6"}>
            <h1 className={"title"}>О нас</h1>
            <article className={"bigger"}>
              <p>
                Del-corso- это бренд, над которым работает команда
                профессионалов, влюбленных в обувь.
              </p>
              <p>&nbsp;</p>
              <p>
                При создании коллекций мы делаем ставку на современный дизайн и
                качественные материалы. Удобная колодка, натуральные
                материалы,&nbsp;хорошее качество - все это неотъемлемые
                составляющие обуви Del-corso. Нам важно, чтобы вы без лишних
                усилий могли подобрать нужную&nbsp;обувь и интегрировать ее в
                свои образы. Поэтому в наших коллекциях&nbsp; представлены
                несколько направлений:
              </p>
              <p>&nbsp;</p>
              <p>
                &nbsp;-базовые модели, которые являются отличным решением на
                каждый день. Они дают возможность сохранять индивидуальность,
                чувствуя при&nbsp;этом комфорт.
              </p>
              <p>&nbsp;</p>
              <p>
                -актуальные модели, отличающиеся современным&nbsp; дизайном,
                помогут сформировать неповторимые образы с учетом последних
                тенденций. Такая обувь даст возможность сохранить ощущение
                уникальности, где бы вы не были.
              </p>
              <p style={{ textAlign: "justify" }}>&nbsp;</p>
              <p style={{ textAlign: "justify" }}>
                Мы постоянно совершенствуем уровень своего сервиса и вкладываем
                невероятный объем любви в создание каждой коллекции.
              </p>
              <p style={{ textAlign: "justify" }}>Мы рады работать для Вас!</p>
              <p style={{ textAlign: "justify" }}>&nbsp;</p>
              <p style={{ textAlign: "justify" }}>&nbsp;</p>
              <p>&nbsp;</p>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
