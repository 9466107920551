import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { store } from "./store/index";
import "react-toastify/dist/ReactToastify.css";

import App from "./app";
import { Provider } from "react-redux";

export const REACT_APP_API_URL = "https://apidelcorso.by/";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
