import React from "react";
import { useSelector } from "react-redux";
import CatalogItem from "./catalogItem";

import "./catalog.scss";

const CatalogList = ({ filterIsRolled }) => {
  const products = useSelector((state) => state.products.allProducts);
  return (
    <div>
      <div
        className={
          "row row-catalog-filters justify-content-between align-items-center"
        }
      >
        <div
          className={"col-lg-12 col-auto col-filters-count"}
          style={{ marginBottom: "15px", fontSize: "16px", color: "#606060" }}
        >
          {products ? `${products.length}` : 0}{" "}
          {products &&
          (products.length % 10 === 2 || products.length % 10 === 3) &&
          products.length !== 12 &&
          products.length !== 13
            ? "позиции"
            : products && products.length % 10 === 1
            ? "позиция"
            : "позиций"}
        </div>
        <div className="col-auto col-filters-button">
          <a
            onClick={filterIsRolled}
            className="button small gray _js-b-toggle-filters"
          >
            Фильтр
          </a>
        </div>
      </div>
      <div className={"w-catalog-products-list"}>
        <div className={`row`}>
          {products.map((product) => (
            <CatalogItem product={product} isCatalog={true} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CatalogList;
