import React from "react";
import foot from "./table_size.jpg";

import "./modalSize.scss";

const ModalSizeTable = ({ onClose }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="fancybox-stage ppp">
          <div className="fancybox-slide fancybox-slide--inline fancybox-slide--current fancybox-slide--complete qqq">
            <div
              id="text_size"
              className={"aaa"}
              style={{ display: "inline-block" }}
            >
              <p
                className={"bbb"}
                style={{
                  textAlign: "left",
                  fontSize: "140%",
                  lineHeight: "1.2",
                  fontWeight: "normal",
                  fontFamily: "'Tinos', sans-serif",
                }}
              >
                <strong className={"ccc"}>Размеры</strong>
              </p>
              <table width="100%" align="center" className={"ddd"}>
                <tbody className={"eee"}>
                  <tr className="tr_head fff">
                    <td className={"hhh"}>
                      <div className={"iii"}>Российский размер</div>
                    </td>
                    <td className={"hhh"}>
                      <div className={"iii"}>Размер производителя</div>
                    </td>
                    <td className={"hhh"}>
                      <div className={"iii"}>Длина стопы, в см</div>
                    </td>
                  </tr>
                  <tr className="data-size ggg">
                    <td className={"hhh"}>
                      <div>34</div>
                    </td>
                    <td className={"hhh"}>
                      <div>34</div>
                    </td>
                    <td className={"hhh"}>
                      <div>21,5</div>
                    </td>
                  </tr>
                  <tr className="data-size ggg">
                    <td className={"hhh"}>
                      <div>35</div>
                    </td>
                    <td className={"hhh"}>
                      <div>35</div>
                    </td>
                    <td className={"hhh"}>
                      <div>22,5</div>
                    </td>
                  </tr>
                  <tr className="data-size ggg">
                    <td>
                      <div>36</div>
                    </td>
                    <td>
                      <div>36</div>
                    </td>
                    <td>
                      <div>23</div>
                    </td>
                  </tr>
                  <tr className="data-size ggg">
                    <td className={"hhh"}>
                      <div>37</div>
                    </td>
                    <td>
                      <div>37</div>
                    </td>
                    <td>
                      <div>23,5-24</div>
                    </td>
                  </tr>
                  <tr className="data-size ggg">
                    <td>
                      <div>38</div>
                    </td>
                    <td>
                      <div>38</div>
                    </td>
                    <td>
                      <div>24,5</div>
                    </td>
                  </tr>
                  <tr className="data-size ggg">
                    <td>
                      <div>39</div>
                    </td>
                    <td>
                      <div>39</div>
                    </td>
                    <td>
                      <div>25</div>
                    </td>
                  </tr>
                  <tr className="data-size ggg">
                    <td>
                      <div>40</div>
                    </td>
                    <td>
                      <div>40</div>
                    </td>
                    <td>
                      <div>25,5-26</div>
                    </td>
                  </tr>
                  <tr className="data-size ggg">
                    <td>
                      <div>41</div>
                    </td>
                    <td>
                      <div>41</div>
                    </td>
                    <td>
                      <div>26,5</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className={"jjj"}>
                <span
                  className={"kkk"}
                  style={{
                    color: "#222222",
                    fontFamily: "'Open Sans', sans-serif",
                    fontSize: "12px",
                  }}
                >
                  <img
                    className={"lll"}
                    style={{ float: "right" }}
                    src={foot}
                    alt=""
                    width="168"
                    height="84"
                  />
                  Вам понадобится провести измерения с помощью сантиметровой
                  ленты.
                </span>
                <span
                  className={"mmm"}
                  style={{
                    color: "#222222",
                    fontFamily: "'Open Sans', sans-serif",
                    fontSize: "12px",
                  }}
                >
                  &nbsp;
                </span>
              </p>
              <p className={"jjj"}>
                <span
                  className={"mmm"}
                  style={{
                    color: "#222222",
                    fontFamily: "'Open Sans', sans-serif",
                    fontSize: "12px",
                  }}
                >
                  Поставьте ногу на чистый лист бумаги. Отметьте крайние границы
                  ступни и измерьте расстояние между самыми удаленными точками
                  стопы.
                </span>
              </p>
              <p className={"jjj"}>&nbsp;</p>
              <button
                data-fancybox-close=""
                className="fancybox-close-small"
                title="Close"
                onClick={onClose}
              ></button>
              <button
                data-fancybox-close=""
                className="fancybox-close-small"
                title="Close"
                onClick={onClose}
              ></button>
            </div>
          </div>
        </div>

        {/*/!*<div className="close-button" onClick={onClose}>*!/*/}
        {/*/!*  <div className="close-icon">&#10005;</div>*!/*/}
        {/*/!*</div>*!/*/}
        {/*{children}*/}
      </div>
    </div>
  );
};

export default ModalSizeTable;
