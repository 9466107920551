import React, { useState } from "react";
import { REACT_APP_API_URL } from "../../index";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const optionItems = [
  { id: 1, name: "Беларусь", currency: "BYN", className: "select-country-BYN" },
  { id: 2, name: "Россия", currency: "RUB", className: "select-country-RUB" },
  {
    id: 3,
    name: "Другие страны",
    currency: "USD",
    className: "select-country-USD",
  },
];

const OrderForm = () => {
  const navigate = useNavigate();
  const idsCartProducts = useSelector(
    (state) => state.products.idsCartProducts
  );
  const cartProducts = useSelector((state) => state.products.cartProducts);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [surname, setSurname] = useState("");
  const [country, setCountry] = useState(optionItems[0].name);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [address, setAddress] = useState("");

  const [phoneRedBorder, setPhoneRedBorder] = useState(false);
  const [emailRedBorder, setEmailRedBorder] = useState(false);

  const createOrderL = () => {
    const orderProducts = cartProducts.map((product) => {
      return {
        product_id: product.id,
        size: idsCartProducts.find((id) => id.id === product.id)
          ? idsCartProducts.find((id) => id.id === product.id).size
          : "",
        quantity: 1,
      };
    });
    const today = new Date(); // Создаем объект Date для текущей даты

    // Извлекаем год, месяц и день из объекта Date
    const year = today.getFullYear(); // Год (YYYY)
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Месяц (MM)
    const day = String(today.getDate()).padStart(2, "0"); // День (DD)

    // Формируем строку в формате "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;

    const phonePattern = /^\+\d{12}$/;
    const emailPattern =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const phoneMatchResult = phone.match(phonePattern);
    const emailMatchResult = email.match(emailPattern);

    if (phoneMatchResult === null) {
      setPhone("+375");
      setPhoneRedBorder(true);
    }
    if (email && emailMatchResult === null) {
      setEmailRedBorder(true);
    }

    if (
      firstName &&
      lastName &&
      surname &&
      country &&
      phoneMatchResult !== null &&
      (emailMatchResult !== null || email === "") &&
      address
    ) {
      const body = {
        product_quantities: orderProducts,
        first_name: firstName,
        last_name: lastName,
        surname: surname,
        country: country,
        telephone_number: phone,
        email: email,
        comment: comment,
        address: address,
        order_date: formattedDate,
      };
      // console.log(body);
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body), // Преобразуем данные в формат JSON
      };

      fetch(`${REACT_APP_API_URL}/api/v1/orders/`, options)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          } else {
            Cookies.remove("cartProducts");
            navigate("/thanks-for-ordering");
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  };

  return (
    <div className="w-cart-callback-form">
      <div id="form-cart-order">
        <input
          type="hidden"
          name="_token"
          value="afhfhOlr64VXIShYRv6P6spRhjmluWlIbIGcXi9D"
        />
        <div className="row">
          <div className="col-xl-8 col-lg-9 col-md-12 col-sm-6 col-12">
            <div className="input mb-20">
              <select
                name="country_id"
                className="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                {optionItems.map((item) => (
                  <option
                    value={item.name}
                    className={item.className}
                    data-currency={item.currency}
                  >
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-xl-8 col-lg-9 col-md-12 col-sm-6 col-12">
            <div className="input mb-20">
              <input
                type="text"
                className="input__default"
                placeholder="Фамилия"
                name="last_name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div className="col-xl-8 col-lg-9 col-md-12 col-sm-6 col-12">
            <div className="input mb-20">
              <input
                type="text"
                className="input__default"
                placeholder="Имя"
                name="first_name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
          <div className="col-xl-8 col-lg-9 col-md-12 col-sm-6 col-12">
            <div className="input mb-20">
              <input
                type="text"
                className="input__default"
                placeholder="Отчество"
                name="surname"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
            </div>
          </div>
          <div className="col-xl-8 col-lg-9 col-md-12 col-sm-6 col-12">
            <div className="input mb-20">
              <input
                type="text"
                className="input__default"
                placeholder="Ваш E-mail"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailRedBorder(false);
                }}
                style={{ borderColor: emailRedBorder ? "red" : "" }}
              />
            </div>
          </div>
          <div className="col-xl-8 col-lg-9 col-md-12 col-sm-6 col-12">
            <div className="input mb-20">
              <input
                type="tel"
                className="input__default"
                placeholder="Ваш телефон"
                name="phone"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setPhoneRedBorder(false);
                }}
                style={{ borderColor: phoneRedBorder ? "red" : "" }}
              />
            </div>
          </div>
          <div className="col-xl-8 col-lg-9 col-md-12 col-sm-6 col-12">
            <div className="input mb-20">
              <input
                type="text"
                className="input__default"
                placeholder="Адрес доставки: город, улица, номер дома и квартиры"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
          <div className="col-xl-8 col-lg-9 col-md-12 col-sm-6 col-12">
            <div className="input mb-20">
              <textarea
                className="textarea__default"
                placeholder="Ваш комментарий (не обязательно)"
                name="mark"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="col-xl-8 col-lg-9 col-md-12 col-sm-6 col-12">
            <div className="input mb-20">
              <button
                className="button uppercase block btn-to-cart"
                onClick={createOrderL}
              >
                {"Оформить заказ"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderForm;
