import React from "react";

import "./footer.scss";
import { Email, PhoneNumber1, PhoneNumber2, WORK_SCHEDULE } from "../../../app";

const DEFAULT_CLASSNAME = "footer";

const ContactInfoUnit = () => {
  return (
    <div className={`${DEFAULT_CLASSNAME}_navigation_item`}>
      <div className={`${DEFAULT_CLASSNAME}_navigation_sub-item-title`}>
        {"КОНТАКТЫ"}
      </div>
      <div className={`${DEFAULT_CLASSNAME}_navigation_sub-item`}>
        <PhoneNumber1 />
      </div>
      <div className={`${DEFAULT_CLASSNAME}_navigation_sub-item`}>
        <PhoneNumber2 />
      </div>
      <div className={`${DEFAULT_CLASSNAME}_navigation_sub-item`}>
        {`Режим работы: ${WORK_SCHEDULE}`}
      </div>
      <div className={`${DEFAULT_CLASSNAME}_navigation_sub-item`}>
        <Email />
      </div>
    </div>
  );
};

export default ContactInfoUnit;
