import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchCartProducts,
  fetchFavoriteProducts,
} from "../../asyncActions/catalog";

const SelectorDeferredType = ({ isCart }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const idsCartProducts = useSelector(
    (state) => state.products.idsCartProducts
  );
  const idsFavoriteProducts = useSelector(
    (state) => state.products.idsFavoriteProducts
  );

  return (
    <div className="row row-cart-top-tabs justify-content-center">
      <div className="col col-auto">
        <a
          onClick={() => {
            navigate("/cart");
            if (idsCartProducts.length > 0) {
              dispatch(
                fetchCartProducts(idsCartProducts.map((item) => item.id))
              );
            }
          }}
          className={`top-tab__link  ${isCart ? "_active" : ""} `}
        >
          <div className="section-name _h1 uppercase bold name">{`Корзина`}</div>
          <div className="description js-cart-count">{`${idsCartProducts.length} позиция`}</div>
        </a>
      </div>
      <div className="col col-auto">
        <a
          onClick={() => {
            navigate("/favorites");
            if (idsFavoriteProducts.length > 0) {
              dispatch(fetchFavoriteProducts(idsFavoriteProducts));
            }
          }}
          className={`top-tab__link  ${isCart ? "" : "_active"} `}
        >
          <div className="section-name _h1 uppercase bold name">
            {`Избранное`}
          </div>
          <div className="description js-favorite-count">{`${idsFavoriteProducts.length} позиция`}</div>
        </a>
      </div>
    </div>
  );
};

export default SelectorDeferredType;
