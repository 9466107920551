import React from "react";
import { useSelector } from "react-redux";
import { CATALOG_ROUTE } from "../../../routes";

const ColorFragment = () => {
  const product = useSelector((state) => state.products.product);
  return (
    <div>
      <div className={"fragment_title"}>Цвет</div>
      <div className={"product_info_description_color"}>
        <div
          className="product_info_description_color_border"
          style={{ backgroundColor: product.color.hex_number }}
        ></div>
        {product.colors.map((brother) => (
          <a
            href={`${CATALOG_ROUTE}/${product.type_category.name}/item/${brother.id}`}
          >
            <div
              className="product_info_description_color_without_border"
              style={{
                marginLeft: "15px",
                backgroundColor: `${brother.hex_number}`,
              }}
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default ColorFragment;
