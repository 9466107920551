import Catalog from "./page/catalog";
import Home from "./page/home";
import Novelty from "./page/novelty";
import Sellout from "./page/sellout";
import Contact from "./page/contact";
import Favorites from "./page/favorites";
import Cart from "./page/cart";
import Product from "./page/product";
import Search from "./page/search";
import AboutUs from "./page/information/aboutUs";
import Offer from "./page/information/offer";
import Confidential from "./page/information/confidential";
import Certificate from "./page/information/certificate";
import Delivery from "./page/information/delivery";
import Returns from "./page/information/returns";
import SecurePay from "./page/information/securePay";
import ThanksForOrdering from "./page/information/thanksForOrdering";
import DeliveryThroughoutBelarus from "./page/information/deliveryThroughoutBelarus";

export const HOME_ROUTE = "/";
export const CATALOG_ROUTE = "/catalog";
export const CATALOG_WITH_TYPE_ROUTE = "/catalog/:type";
export const NOVELTY_ROUTE = "/novelty";
export const NOVELTY_WITH_TYPE_ROUTE = "/novelty/:type";
export const SELLOUT_ROUTE = "/sellout";
export const SELLOUT_WITH_TYPE_ROUTE = "/sellout/:type";
export const CONTACT_ROUTE = "/contact";
export const FAVORITES_ROUTE = "/favorites";
export const CART_ROUTE = "/cart";
export const PRODUCT_ROUTE = CATALOG_WITH_TYPE_ROUTE + "/item/:id";
export const SEARCH_ROUTE = "/search";

export const ABOUT_US_ROUTE = "/about-us";
export const OFFER_ROUTE = "/offer";
export const CONFIDENTIAL_ROUTE = "/confidential";
export const CERTIFICATE_ROUTE = "/certificate";
export const DELIVERY_ROUTE = "/delivery";
export const RETURNS_ROUTE = "/returns";
export const SECURE_PAY_ROUTE = "/secure-pay";
export const THANKS_FOR_ORDERING_ROUTE = "/thanks-for-ordering";
export const DELIVERY_THROUGHOUT_BELARUS_ROUTE = "/delivery-throughout-belarus";

export const routes = [
  { path: HOME_ROUTE, Component: Home },
  { path: CATALOG_ROUTE, Component: Catalog },
  { path: CATALOG_WITH_TYPE_ROUTE, Component: Catalog },
  { path: NOVELTY_ROUTE, Component: Novelty },
  { path: NOVELTY_WITH_TYPE_ROUTE, Component: Novelty },
  { path: SELLOUT_ROUTE, Component: Sellout },
  { path: SELLOUT_WITH_TYPE_ROUTE, Component: Sellout },
  { path: CONTACT_ROUTE, Component: Contact },
  { path: FAVORITES_ROUTE, Component: Favorites },
  { path: CART_ROUTE, Component: Cart },
  { path: PRODUCT_ROUTE, Component: Product },
  { path: SEARCH_ROUTE, Component: Search },
  //
  { path: ABOUT_US_ROUTE, Component: AboutUs },
  { path: OFFER_ROUTE, Component: Offer },
  { path: CONFIDENTIAL_ROUTE, Component: Confidential },
  { path: CERTIFICATE_ROUTE, Component: Certificate },
  { path: DELIVERY_ROUTE, Component: Delivery },
  { path: RETURNS_ROUTE, Component: Returns },
  { path: SECURE_PAY_ROUTE, Component: SecurePay },
  { path: THANKS_FOR_ORDERING_ROUTE, Component: ThanksForOrdering },
  {
    path: DELIVERY_THROUGHOUT_BELARUS_ROUTE,
    Component: DeliveryThroughoutBelarus,
  },
];
