import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import { searchParametersReducer } from "./searchParametersReducer";
import thunk from "redux-thunk";
import { catalogReducer } from "./catalogReducer";

const reducers = combineReducers({
  searchParameters: searchParametersReducer,
  products: catalogReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);
