import React from "react";

import HeaderBottom from "./headerBottom";
import HeaderTop from "./headerTop";
import Divider from "./divider";

import "./header.scss";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "./breadcrumbs";

export const Header = () => {
  const navigate = useNavigate();

  return (
    <div>
      <HeaderTop />
      <div
        onClick={() => navigate("/")}
        style={{ position: "relative", top: "44px" }}
      >
        <Divider />
      </div>
      <HeaderBottom />
      <Breadcrumbs />
    </div>
  );
};
