import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchAllNoveltyProducts } from "../asyncActions/catalog";
import { fetchTypes } from "../asyncActions/searchParameters";
import NoveltyList from "../components/novelty/noveltyList";
import Title from "../components/common/title";

const Novelty = () => {
  const noveltyProducts = useSelector(
    (state) => state.products.noveltyProducts
  );
  const types = useSelector((state) => state.searchParameters.types);
  const dispatch = useDispatch();
  const { type } = useParams();

  useEffect(() => {
    dispatch(fetchTypes());
    const query = type ? `?type=${type}` : "";
    dispatch(fetchAllNoveltyProducts(query));
  }, []);

  return (
    <div className={"catalog"}>
      <Title str={"Новинки - новое поступление в del-corso.by"} />
      <>
        <div className={"catalog_info col col-side-content"}>
          <div className={"catalog_info_title"}>{`Новинки`}</div>
          <div
            style={{
              marginBottom: "15px",
              fontSize: "16px",
              color: "#606060",
            }}
          >
            {noveltyProducts ? `${noveltyProducts.length}` : 0}{" "}
            {noveltyProducts &&
            (noveltyProducts.length % 10 === 2 ||
              noveltyProducts.length % 10 === 3) &&
            noveltyProducts.length !== 12 &&
            noveltyProducts.length !== 13
              ? "позиции"
              : noveltyProducts && noveltyProducts.length % 10 === 1
              ? "позиция"
              : "позиций"}
          </div>

          <div className="w-catalog-tags-list">
            <div className="row">
              <div className="col-auto">
                <a href="/novelty" className="tag__link ">
                  Все новинки
                </a>
              </div>
              {types.map((type) => (
                <div className="col-auto">
                  <a href={`/novelty/${type.name}`} className="tag__link ">
                    {type.name}
                  </a>
                </div>
              ))}
            </div>
          </div>

          <NoveltyList isHome={false} />
          <div className="mb-10">
            <hr />
          </div>
        </div>
      </>
    </div>
  );
};

export default Novelty;
