import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSelectedSizeAction,
  deleteSelectedSizeAction,
  setSelectedSizesAction,
} from "../../../store/searchParametersReducer";

const SizesParam = () => {
  const sizes = useSelector((state) => state.searchParameters.sizes);
  const selectedSizes = useSelector(
    (state) => state.searchParameters.selectedSizes
  );

  const dispatch = useDispatch();

  const handleCheckboxChange = (size) => {
    if (selectedSizes.includes(size)) {
      return dispatch(deleteSelectedSizeAction(size));
    } else {
      return dispatch(addSelectedSizeAction(size));
    }
  };

  return (
    <div className={"w-filter"}>
      {sizes.length > 0 && (
        <>
          <div className={"filter-name"}>
            <div className={"row row-filter-name justify-content-between"}>
              <div className={"col-auto"}>Размер</div>
              <div className={"col-auto col-clear"}>
                <a
                  className={"clear-filter"}
                  onClick={() => {
                    dispatch(setSelectedSizesAction([]));
                  }}
                >
                  Сбросить
                </a>
              </div>
            </div>
          </div>

          <div className={"row"}>
            {sizes.map((size) => (
              <div className="col-6">
                <div className="w-filter-checkbox">
                  <div>
                    <div className="input checkbox">
                      <input
                        id={`sizes[${size.id}]`}
                        type="checkbox"
                        name="sizes[]"
                        className={"checkbox__default checkbox__hidden"}
                        checked={selectedSizes.includes(size.id)}
                        onChange={() => handleCheckboxChange(size.id)}
                      />
                      <div className="styled">
                        <label htmlFor={`sizes[${size.id}]`} className="frame">
                          <span className="inset"></span>
                        </label>
                      </div>
                      <label htmlFor={`sizes[${size.id}]`} className="label">
                        {size.size}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SizesParam;
