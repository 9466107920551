import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPriceFromAction,
  setPriceToAction,
} from "../../../store/searchParametersReducer";

const PriceParam = () => {
  const priceFrom = useSelector((state) => state.searchParameters.priceFrom);
  const priceTo = useSelector((state) => state.searchParameters.priceTo);

  const dispatch = useDispatch();
  function handlePriceFromChange() {
    dispatch(
      setPriceFromAction(Number(document.getElementById("priceFrom").value))
    );
  }
  function handlePriceToChange() {
    dispatch(
      setPriceToAction(Number(document.getElementById("priceTo").value))
    );
  }

  return (
    <div className={"w-filter"}>
      <div className={"filter-name"}>
        <div
          className={
            "row row-filter-name justify-content-between filter-item__header"
          }
        >
          <div className={"col-auto"}>
            {`ЦЕНА, `}
            <span className={"filter-item__small"}>бел.руб.</span>
          </div>
          <div className={"col-auto col-clear"}>
            <a
              onClick={() => {
                dispatch(setPriceFromAction(0));
                dispatch(setPriceToAction(500));
              }}
              className={"clear-filter"}
            >
              Сбросить
            </a>
          </div>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-6"}>
          <label htmlFor="priceFrom">От:</label>
          <input
            className={"filter-input-control"}
            type="number"
            step="0.01"
            id="priceFrom"
            value={priceFrom}
            onInput={handlePriceFromChange}
          />
        </div>
        <div className={"col-6"}>
          <label htmlFor="priceTo">До:</label>
          <input
            className={"filter-input-control"}
            type="number"
            step="0.01"
            id="priceTo"
            value={priceTo}
            onChange={handlePriceToChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceParam;
