import React from "react";

const LoadingComponent = () => {
  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div className="loading-spinner"></div>
      <p>Loading...</p>
    </div>
  );
};

export default LoadingComponent;
