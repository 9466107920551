import React from "react";

import "./instagram.scss";
import inst1 from "./inst-1.jpg";
import inst2 from "./inst-2.jpg";
import inst3 from "./inst-3.jpg";
import inst4 from "./inst-4.jpg";
import { FaInstagram } from "react-icons/fa6";

const URL_INSTAGRAM = "https://www.instagram.com/del.corso.minsk/?hl=ru";

const instPost = [
  {
    instagramPostUrl: URL_INSTAGRAM,
    imageUrl: inst1,
  },
  {
    instagramPostUrl: URL_INSTAGRAM,
    imageUrl: inst2,
  },
  {
    instagramPostUrl: URL_INSTAGRAM,
    imageUrl: inst3,
  },
  {
    instagramPostUrl: URL_INSTAGRAM,
    imageUrl: inst4,
  },
];

const InstagramList = () => {
  return (
    <div className="s-line s-index-insta pt-40 pb-20">
      <div className="container">
        <a href={URL_INSTAGRAM}>
          <div
            className="section-name _h3 uppercase bold align-center mb-10"
            style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
          >
            <div>{`Мы в Instagram`}</div>
            <div style={{ marginLeft: "10px", marginTop: "2.5px" }}>
              <FaInstagram />
            </div>
          </div>
        </a>

        <div className="mb-20 align-center" />
        <div className="w-insta-list">
          {/*Нахуй не нужен класс*/}
          <div className="row">
            {instPost.map((item) => (
              <div className="col-md-3 col-6 col">
                <div className="w-insta-item">
                  <a
                    href={item.instagramPostUrl}
                    target="_blank"
                    className="block__link"
                    // onClick="ym(57380242, 'reachGoal', 'BTN_INSTAGRAM'); return true;"
                  >
                    <div className="r-box">
                      <div
                        className="r-img"
                        style={{
                          backgroundImage: `url("${item.imageUrl}")`,
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <div className="absolute-overlay"></div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstagramList;
