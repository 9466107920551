import React from "react";
import "./header.scss";
import { useNavigate } from "react-router-dom";

const DEFAULT_CLASSNAME = "header";

const Divider = () => {
  const navigate = useNavigate();

  return (
    <div className={`divider`}>
      {" "}
      <div
        className={`${DEFAULT_CLASSNAME}_wrapper_divider`}
        onClick={() => navigate("/")}
      >
        <span>{"del-corso"}</span>
      </div>
    </div>
  );
};

export default Divider;
