import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CatalogList from "../components/catalog/catalogList";
import { useDispatch } from "react-redux";
import {
  fetchAllProducts,
  fetchSearchAllProducts,
} from "../asyncActions/catalog";
import Title from "../components/common/title";

const Search = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const keywords = searchParams.get("keywords") || "";

  const dispatch = useDispatch();

  useEffect(() => {
    if (keywords) {
      dispatch(fetchSearchAllProducts(keywords));
    } else {
      dispatch(fetchAllProducts());
    }
  }, [keywords]);

  return (
    <div className={"catalog"}>
      <Title str={"Поиск по сайту Del-corso.by"} />

      <div className={"catalog_info"}>
        <div className={"catalog_info_title"}>{`поиск: ${keywords}`}</div>
        <CatalogList />
      </div>
    </div>
  );
};

export default Search;
