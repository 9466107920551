import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSelectedColorAction,
  deleteSelectedColorAction,
  setSelectedColorsAction,
} from "../../../store/searchParametersReducer";

const ColorsParam = () => {
  const colors = useSelector((state) => state.searchParameters.colors);
  const selectedColors = useSelector(
    (state) => state.searchParameters.selectedColors
  );

  const dispatch = useDispatch();

  const handleCheckboxChange = (color) => {
    if (selectedColors.includes(color)) {
      return dispatch(deleteSelectedColorAction(color));
    } else {
      return dispatch(addSelectedColorAction(color));
    }
  };

  return (
    <div className={"w-filter"}>
      {colors.length > 0 && (
        <>
          <div className={"filter-name"}>
            <div className={"row row-filter-name justify-content-between"}>
              <div className={"col-auto"}>Цвет</div>
              <div className={"col-auto col-clear"}>
                <a
                  className={"clear-filter"}
                  onClick={() => {
                    dispatch(setSelectedColorsAction([]));
                  }}
                >
                  Сбросить
                </a>
              </div>
            </div>
          </div>
          {colors.map((color) => (
            <div className={"w-filter-checkbox"}>
              <div>
                <div className="input checkbox">
                  <input
                    id={`color[${color.id}]`}
                    type="checkbox"
                    name="colors[]"
                    className={"checkbox__default checkbox__hidden"}
                    checked={selectedColors.includes(color.id)}
                    onChange={() => handleCheckboxChange(color.id)}
                  />
                  <div className="styled">
                    <label htmlFor={`color[${color.id}]`} className="frame">
                      <span className={"inset"}></span>
                    </label>
                  </div>
                  <label
                    htmlFor={`color[${color.id}]`}
                    className={"label"}
                    style={{ textTransform: "lowercase" }}
                  >
                    {` ${color.color}`}
                  </label>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ColorsParam;
