import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";

const CharacteristicFragment = () => {
  const product = useSelector((state) => state.products.product);

  const [propertyIsOpen, setPropertyIsOpen] = useState(true);

  const properties = [
    { name: "Материал верха", value: product.upper_material },
    { name: "Материал подкладки", value: product.lining_material },
    {
      name: "Сезон",
      value: product.season_categories
        .map((season) => season.charAt(0).toUpperCase() + season.slice(1))
        .join(", "),
    },
    { name: "Полнота", value: product.completeness },
    { name: "Соответствие размера", value: product.true_to_size },
    { name: "Страна производства", value: product.country_of_origin },
    { name: "Гарантийный срок", value: `${product.guarantee_period} дней` },
    { name: "Импортер", value: product.importer },
  ];

  return (
    <div className={"fragment"}>
      <div className={"fragment_title"}>
        {`Характеристики`}
        {propertyIsOpen ? (
          <FaAngleUp
            style={{ margin: "0 6px", cursor: "pointer" }}
            onClick={() => setPropertyIsOpen(false)}
          />
        ) : (
          <FaAngleDown
            style={{ margin: "0 6px", cursor: "pointer" }}
            onClick={() => setPropertyIsOpen(true)}
          />
        )}
      </div>
      {propertyIsOpen && (
        <div style={{ margin: "15px 0 0" }}>
          {properties.map(
            (property) =>
              property.value && (
                <div className={"product-property"}>
                  <div className={"product-property__left"}>
                    <span className={"product-property__name"}>
                      {property.name}
                    </span>
                  </div>
                  <div className={"product-property__right"}>
                    <span className={"product-property__value"}>
                      {`${property.value}`}
                    </span>
                  </div>
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default CharacteristicFragment;
