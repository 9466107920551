import React from "react";
import { useSelector } from "react-redux";

const PriceFragment = () => {
  let product = useSelector((state) => state.products.product);
  const isDiscountProduct = Object.keys(product.discount).length !== 0;

  return (
    <div className={"product_info_description_price"}>
      <div
        className={`product_info_description_price_${
          isDiscountProduct ? "old" : "full"
        }`}
      >
        <div>{`${product.price} руб.`}</div>
      </div>

      {isDiscountProduct && (
        <div className={"product_info_description_price_sale"}>
          <div>{`${product.discount.discount_price} руб.`}</div>
        </div>
      )}
    </div>
  );
};

export default PriceFragment;
