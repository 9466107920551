import React from "react";
import MenuBar from "../../components/information/menuBar";

import "../../components/information/informations.scss";
import Title from "../../components/common/title";

const Certificate = () => {
  return (
    <div className={"information"}>
      <Title str={"Декларации о соответствии"} />
      <div className={"product_info"}>
        <div style={{ padding: "0 15px 40px" }}>
          <MenuBar />
        </div>
        <div className={"product_info_description"}>
          <div className={"zx-6"}>
            <h1 className={"title"}>Декларации о соответствии</h1>
            <article className={"bigger"}>
              <p className="MsoNormal">
                <span lang="EN-US" className={"zx-9"}>
                  BY
                </span>
                /112 11.02{" "}
                <span lang="EN-US" className={"zx-9"}>
                  TP
                </span>
                017
                <span lang="EN-US" className={"zx-9"}>
                  &nbsp;
                </span>
                019 13766
              </p>
              <p className="MsoNormal">
                <span lang="EN-US" className={"zx-9"}>
                  RU{" "}
                </span>
                Д
                <span lang="EN-US" className={"zx-9"}>
                  -IT. HA88.B/03589/19
                </span>
              </p>
              <p className="MsoNormal">
                <span lang="EN-US" className={"zx-9"}>
                  RU{" "}
                </span>
                Д
                <span lang="EN-US" className={"zx-9"}>
                  -TR.{" "}
                </span>
                АИ
                <span lang="EN-US" className={"zx-9"}>
                  77.B.19189/19
                </span>
              </p>
              <p className="MsoNormal">
                <span lang="EN-US" className={"zx-9"}>
                  RU{" "}
                </span>
                Д
                <span lang="EN-US" className={"zx-9"}>
                  -CN.
                </span>
                АД
                <span lang="EN-US" className={"zx-9"}>
                  71.B.03647/19
                </span>
              </p>
              <p className="MsoNormal">
                <span lang="EN-US" className={"zx-9"}>
                  RU Д-CN.АБ87.B.01427/20
                </span>
              </p>
              <p className="MsoNormal">
                <span lang="EN-US" className={"zx-9"}>
                  BY/112 11.02 TP017&nbsp;019 13855
                </span>
              </p>
              <p className="MsoNormal">
                <span lang="EN-US" className={"zx-9"}>
                  BY/112 11.02 TP017&nbsp;019 13893
                </span>
              </p>
              <p className="MsoNormal">
                <span lang="EN-US" className={"zx-9"}>
                  BY/112 11.02 TP017&nbsp;019 13987
                </span>
              </p>
              <p className="MsoNormal">
                <span lang="EN-US" className={"zx-9"}>
                  BY/112 11.02 TP017&nbsp;019 13989
                </span>
              </p>
              <p className="MsoNormal">
                <span lang="EN-US" className={"zx-9"}>
                  BY/112 11.02 TP017&nbsp;019 14069
                </span>
              </p>
              <p className="MsoNormal">
                <span lang="EN-US" className={"zx-9"}>
                  BY/112 11.02 TP017&nbsp;019 13928
                </span>
              </p>
              <p className="MsoNormal">RU Д-TR.PA03.B.39493/22</p>
              <p className="MsoNormal">RU Д-CN.HB29.B.03949/20</p>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
