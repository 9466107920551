const defaultState = {
  allProducts: [],
  noveltyProducts: [],
  saleProducts: [],
  favoriteProducts: [],
  cartProducts: [],
  idsFavoriteProducts: [],
  idsCartProducts: [],
  product: {},
  selectedSize: {},
  availableSizes: [],
  photosForMainSlider: [],
  photosForMainPopular: [],
};

const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";
const SET_NOVELTY_PRODUCTS = "SET_NOVELTY_PRODUCTS";
const SET_SALE_PRODUCTS = "SET_SALE_PRODUCTS";
const SET_PRODUCT = "SET_PRODUCT";

const SET_IDS_FAVORITE_PRODUCTS = "SET_IDS_FAVORITE_PRODUCTS";
const ADD_ID_FAVORITE_PRODUCT = "ADD_ID_FAVORITE_PRODUCT";
const DELETE_ID_FAVORITE_PRODUCT = "DELETE_ID_FAVORITE_PRODUCT";

const SET_IDS_CART_PRODUCTS = "SET_IDS_CART_PRODUCTS";
const ADD_ID_CART_PRODUCT = "ADD_ID_CART_PRODUCT";
const DELETE_ID_CART_PRODUCT = "DELETE_ID_CART_PRODUCT";

const SET_FAVORITE_PRODUCTS = "SET_FAVORITE_PRODUCTS";

const SET_CART_PRODUCTS = "SET_CART_PRODUCTS";

const SET_SELECTED_SIZE = "SET_SELECTED_SIZE";

const SET_AVAILABLE_SIZES = "SET_AVAILABLE_SIZES";

const SET_PHOTOS_FOR_MAIN_SLIDER = "SET_PHOTOS_FOR_MAIN_SLIDER";
const SET_PHOTOS_FOR_MAIN_POPULAR = "SET_PHOTOS_FOR_MAIN_POPULAR";

export const catalogReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ALL_PRODUCTS:
      return { ...state, allProducts: action.payload };
    case SET_NOVELTY_PRODUCTS:
      return { ...state, noveltyProducts: action.payload };
    case SET_SALE_PRODUCTS:
      return { ...state, saleProducts: action.payload };
    case SET_PRODUCT:
      return { ...state, product: action.payload };
    case SET_FAVORITE_PRODUCTS:
      return { ...state, favoriteProducts: action.payload };
    case SET_CART_PRODUCTS:
      return { ...state, cartProducts: action.payload };
    case SET_IDS_FAVORITE_PRODUCTS:
      return { ...state, idsFavoriteProducts: action.payload };
    case ADD_ID_FAVORITE_PRODUCT:
      return {
        ...state,
        idsFavoriteProducts: [...state.idsFavoriteProducts, action.payload],
      };
    case DELETE_ID_FAVORITE_PRODUCT:
      return {
        ...state,
        idsFavoriteProducts: state.idsFavoriteProducts.filter(
          (id) => id !== action.payload
        ),
      };
    case SET_IDS_CART_PRODUCTS:
      return { ...state, idsCartProducts: action.payload };
    case ADD_ID_CART_PRODUCT:
      return {
        ...state,
        idsCartProducts: [...state.idsCartProducts, action.payload],
      };
    case DELETE_ID_CART_PRODUCT:
      return {
        ...state,
        idsCartProducts: state.idsCartProducts.filter(
          (id) => id.id !== action.payload
        ),
      };
    case SET_SELECTED_SIZE:
      return { ...state, selectedSize: action.payload };
    case SET_AVAILABLE_SIZES:
      return { ...state, availableSizes: action.payload };
    case SET_PHOTOS_FOR_MAIN_SLIDER:
      return { ...state, photosForMainSlider: action.payload };
    case SET_PHOTOS_FOR_MAIN_POPULAR:
      return { ...state, photosForMainPopular: action.payload };
    default:
      return state;
  }
};

export const setAllProductsAction = (payload) => ({
  type: SET_ALL_PRODUCTS,
  payload,
});

export const setNoveltyProductsAction = (payload) => ({
  type: SET_NOVELTY_PRODUCTS,
  payload,
});

export const setSaleProductsAction = (payload) => ({
  type: SET_SALE_PRODUCTS,
  payload,
});

export const setProductAction = (payload) => ({
  type: SET_PRODUCT,
  payload,
});

export const setIdsFavoriteProductsAction = (payload) => ({
  type: SET_IDS_FAVORITE_PRODUCTS,
  payload,
});

export const addIdFavoriteProductAction = (payload) => ({
  type: ADD_ID_FAVORITE_PRODUCT,
  payload,
});

export const deleteIdFavoriteProductAction = (payload) => ({
  type: DELETE_ID_FAVORITE_PRODUCT,
  payload,
});

export const setIdsCartProductsAction = (payload) => ({
  type: SET_IDS_CART_PRODUCTS,
  payload,
});

export const addIdCartProductAction = (payload) => ({
  type: ADD_ID_CART_PRODUCT,
  payload,
});

export const deleteIdCartProductAction = (payload) => ({
  type: DELETE_ID_CART_PRODUCT,
  payload,
});

export const setFavoriteProductsAction = (payload) => ({
  type: SET_FAVORITE_PRODUCTS,
  payload,
});

export const setCartProductsAction = (payload) => ({
  type: SET_CART_PRODUCTS,
  payload,
});

export const setSelectedSizeAction = (payload) => ({
  type: SET_SELECTED_SIZE,
  payload,
});

export const setAvailableSizesAction = (payload) => ({
  type: SET_AVAILABLE_SIZES,
  payload,
});

export const setPhotosForMainSlider = (payload) => ({
  type: SET_PHOTOS_FOR_MAIN_SLIDER,
  payload,
});

export const setPhotosForMainPopular = (payload) => ({
  type: SET_PHOTOS_FOR_MAIN_POPULAR,
  payload,
});
