const defaultState = {
  types: [],
  colors: [],
  selectedColors: [],
  seasons: [],
  selectedSeasons: [],
  sizes: [],
  selectedSizes: [],
  priceFrom: 0,
  priceTo: 1000,
};

const SET_TYPES = "SET_TYPES";

const SET_PRICE_FROM = "SET_PRICE_FROM";
const SET_PRICE_TO = "SET_PRICE_TO";

const SET_COLORS = "SET_COLORS";
const SET_SELECTED_COLORS = "SET_SELECTED_COLORS";
const ADD_SELECTED_COLOR = "ADD_SELECTED_COLOR";
const DELETE_SELECTED_COLOR = "DELETE_SELECTED_COLOR";

const SET_SEASONS = "SET_SEASONS";
const SET_SELECTED_SEASONS = "SET_SELECTED_SEASONS";
const ADD_SELECTED_SEASON = "ADD_SELECTED_SEASON";
const DELETE_SELECTED_SEASON = "DELETE_SELECTED_SEASON";

const SET_SIZES = "SET_SIZES";
const SET_SELECTED_SIZES = "SET_SELECTED_SIZES";
const ADD_SELECTED_SIZE = "ADD_SELECTED_SIZE";
const DELETE_SELECTED_SIZE = "DELETE_SELECTED_SIZE";

export const searchParametersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_TYPES:
      return { ...state, types: action.payload };
    case SET_PRICE_FROM:
      return { ...state, priceFrom: action.payload };
    case SET_PRICE_TO:
      return { ...state, priceTo: action.payload };
    case SET_COLORS:
      return { ...state, colors: action.payload };
    case SET_SELECTED_COLORS:
      return { ...state, selectedColors: action.payload };
    case ADD_SELECTED_COLOR:
      return {
        ...state,
        selectedColors: [...state.selectedColors, action.payload],
      };
    case DELETE_SELECTED_COLOR:
      return {
        ...state,
        selectedColors: state.selectedColors.filter(
          (color) => color !== action.payload
        ),
      };
    case SET_SEASONS:
      return { ...state, seasons: action.payload };
    case SET_SELECTED_SEASONS:
      return { ...state, selectedSeasons: action.payload };
    case ADD_SELECTED_SEASON:
      return {
        ...state,
        selectedSeasons: [...state.selectedSeasons, action.payload],
      };
    case DELETE_SELECTED_SEASON:
      return {
        ...state,
        selectedSeasons: state.selectedSeasons.filter(
          (season) => season !== action.payload
        ),
      };
    case SET_SIZES:
      return { ...state, sizes: action.payload };
    case SET_SELECTED_SIZES:
      return { ...state, selectedSizes: action.payload };
    case ADD_SELECTED_SIZE:
      return {
        ...state,
        selectedSizes: [...state.selectedSizes, action.payload],
      };
    case DELETE_SELECTED_SIZE:
      return {
        ...state,
        selectedSizes: state.selectedSizes.filter(
          (size) => size !== action.payload
        ),
      };
    default:
      return state;
  }
};

export const setTypesAction = (payload) => ({ type: SET_TYPES, payload });

export const setPriceFromAction = (payload) => ({
  type: SET_PRICE_FROM,
  payload,
});
export const setPriceToAction = (payload) => ({ type: SET_PRICE_TO, payload });

export const setColorsAction = (payload) => ({ type: SET_COLORS, payload });
export const setSelectedColorsAction = (payload) => ({
  type: SET_SELECTED_COLORS,
  payload,
});
export const addSelectedColorAction = (payload) => ({
  type: ADD_SELECTED_COLOR,
  payload,
});
export const deleteSelectedColorAction = (payload) => ({
  type: DELETE_SELECTED_COLOR,
  payload,
});

export const setSeasonsAction = (payload) => ({ type: SET_SEASONS, payload });
export const setSelectedSeasonsAction = (payload) => ({
  type: SET_SELECTED_SEASONS,
  payload,
});
export const addSelectedSeasonAction = (payload) => ({
  type: ADD_SELECTED_SEASON,
  payload,
});
export const deleteSelectedSeasonAction = (payload) => ({
  type: DELETE_SELECTED_SEASON,
  payload,
});

export const setSizesAction = (payload) => ({ type: SET_SIZES, payload });
export const setSelectedSizesAction = (payload) => ({
  type: SET_SELECTED_SIZES,
  payload,
});
export const addSelectedSizeAction = (payload) => ({
  type: ADD_SELECTED_SIZE,
  payload,
});
export const deleteSelectedSizeAction = (payload) => ({
  type: DELETE_SELECTED_SIZE,
  payload,
});
