import React from "react";
import Title from "../../components/common/title";
import MenuBar from "../../components/information/menuBar";

const brest_cities = [
  `Антополь - ежедневно`,
  `Барановичи - ежедневно`,
  `Белоозерск - вт, чт, сб`,
  `Береза - ежедневно`,
  `Высокое - ср, пт, вскр`,
  `Ганцевичи - вт, чт, сб`,
  `Городище - ежедневно`,
  `Давид - Городок - вт, сб`,
  `Дрогичин - вт, чт, сб`,
  `Жабинка - ср, пт, вскр`,
  `Иваново - вт, чт, сб`,
  `Ивацевичи - ежедневно`,
  `Каменец - ср, пт, вскр`,
  `Кобрин - ежедневно`,
  `Коссово - ежедневно`,
  `Логишин - ср, пт, вскр`,
  `Лунинец - ср, пт, вскр`,
  `Ляховичи - вт, чт, сб`,
  `Малорита - вт, чт, сб`,
  `Микашевичи - ежедневно`,
  `Пинск - ежедневно`,
  `Пружаны - ср, пт, вскр`,
  `Ружаны - ср, пт, вскр`,
  `Столин - вт, сб`,
  `Телеханы - ср, пт, вскр`,
  `Шерешево - ср, пт, вскр`,
];
const vitebsk_cities = [
  `Барань - ежедневно`,
  `Бегомль - ежедневно`,
  `Бешенковичи - ежедневно`,
  `Богушевск - ср, пт, вскр`,
  `Болбасово - ежедневно`,
  `Боровуха - ежедневно`,
  `Браслав - ср, пт, вскр`,
  `Верхнедвинск - ср, пт, вскр`,
  `Ветрино - ср, пт, вскр`,
  `Воропаево - ср, пт, вскр`,
  `Глубокое - ср, пт, вскр`,
  `Городок - ср, пт, вскр`,
  `Дисна - ср, пт, вскр`,
  `Докшицы - ср, пт, вскр`,
  `Дубровно - ср, пт, вскр`,
  `Коханово - ежедневно`,
  `Лепель - ежедневно`,
  `Лиозно - вт, сб`,
  `Миоры - ср, пт, вскр`,
  `Новолукомль - ср, пт, вскр`,
  `Новополоцк - ежедневно`,
  `Оболь - ср, пт, вскр`,
  `Ореховск - ежедневно`,
  `Орша - ежедневно`,
  `Подсвилье - ср, пт, вскр`,
  `Полоцк - ежедневно`,
  `Поставы - ср, пт, вскр`,
  `Руба - ср, пт, вскр`,
  `Сенно - ср, пт, вскр`,
  `Сураж - ср, пт, вскр`,
  `Толочин - ежедневно`,
  `Улла - ср, пт, вскр`,
  `Ушачи - ср, пт, вскр`,
  `Чашники - ср, пт, вскр`,
  `Шарковщина - ср, пт, вск`,
  `Шумилино - ср, пт, вскр`,
];
const gomel_cities = [
  `Белицк - вт, чт, сб`,
  `Брагин - ср, вскр`,
  `Буда-Кошелево - ежедневно`,
  `Василевичи - ср, пт, вскр`,
  `Ветка - вт, чт, сб`,
  `Добруш - вт, чт, сб`,
  `Ельск - вт, сб`,
  `Житковичи - вт, чт, сб`,
  `Жлобин - ежедневно`,
  `Калинковичи - ежедневно`,
  `Копаткевичи - чт, сб`,
  `Корма - вт, чт, сб`,
  `Лельчицы - вт, сб`,
  `Лоев - ср`,
  `Мозырь - ежедневно`,
  `Наровля - вт, сб`,
  `Озаричи- ежедневно`,
  `Октябрьский - вт, чт, сб`,
  `Паричи - ежедневно`,
  `Петриков - чт, сб`,
  `Речица - ежедневно`,
  `Рогачев - ежедневно`,
  `Светлогорск - ежедневно`,
  `Стрешин - вт, чт, сб`,
  `Тереховка - вт, чт, сб`,
  `Уваровичи - ежедневно`,
  `Хойники - ср, вскр`,
  `Чечерск - вт, чт, сб`,
  ``,
];
const grodno_cities = [
  `Березовка - ежедневно`,
  `Большая Берестовица - ср, пт, вскр`,
  `Волковыск - ежедневно`,
  `Вороново - ср, пт, вскр`,
  `Дятлово - ср, пт, вскр`,
  `Желудок - ср, пт, вск`,
  `Зельва - ежедневно`,
  `Ивье - ежедневно`,
  `Козловщина - ср, пт, вскр`,
  `Кореличи - ежедневно`,
  `Красносельский - ежедневно`,
  `Лида - ежедневно`,
  `Любча - ср, пт, вскр`,
  `Мир - ежедневно`,
  `Мосты - ежедневно`,
  `Новогрудок - ежедневно`,
  `Новоельня - ср, пт, вскр`,
  `Острино - ср, пт, вскр`,
  `Островец - ср, пт, вскр`,
  `Ошмяны - ср, пт, вскр`,
  `Порозово - ср, пт, вскр`,
  `Радунь - ср, пт, вскр`,
  `Россь - ежедневно`,
  `Свислочь - ср, пт, вскр`,
  `Скидель - ежедневно`,
  `Слоним - ежедневно`,
  `Сморгонь - ср, пт, вскр`,
  `Щучин - ежедневно`,
  `Юратишки - ср, пт, вскр`,
];
const mogilev_cities = [
  `Белыничи - ежедневно`,
  `Бобруйск - ежедневно`,
  `Быхов - вт, чт, сб`,
  `Глуск - вт, чт, сб`,
  `Глуша - вт, чт, сб`,
  `Горки - ср, пт, вскр`,
  `Дрибин - ср, пт, вскр`,
  `Кировск - ср, пт, вскр`,
  `Климовичи - вт, чт, сб`,
  `Кличев - ср, пт, вскр`,
  `Костюковичи - вт, чт, сб`,
  `Краснополье - вт, чт, сб`,
  `Кричев - вт, чт, сб`,
  `Круглое - ср, пт, вскр`,
  `Мстиславль - вт, чт, сб`,
  `Осиповичи - ежедневно`,
  `Славгород - вт, чт, сб`,
  `Хотимск - чт`,
  `Чаусы - вт, чт, сб`,
  `Чериков - вт, чт, сб`,
  `Шклов - ср, пт, вскр`,
];
const minsk_cities = [
  `Березино - ежедневно`,
  `Бобр - ежедневно`,
  `Борисов - ежедневно`,
  `Вилейка - ср, пт, вскр`,
  `Воложин - ежедневно`,
  `Городея - ежедневно`,
  `Дзержинск - ежедневно`,
  `Жодино - ежедневно`,
  `Заславль - ежедневно`,
  `Зеленый Бор - ср, пт, вскр`,
  `Ивенец - ср, пт, вскр`,
  `Клецк - вт, чт, сб`,
  `Копыль - вт, чт, сб`,
  `Красная Слобода - вт, чт, сб`,
  `Кривичи - ср, пт, вскр`,
  `Крупки - ежедневно`,
  `Логойск - ежедневно`,
  `Любань - вт, чт, сб`,
  `Марьина Горка (Пуховичи) - ежедневно`,
  `Мачулищи - ежедневно`,
  `Молодечно - ежедневно`,
  `Мядель - ср, пт, вскр`,
  `Негорелое - ежедневно`,
  `Несвиж - вт, чт, сб`,
  `Плещеницы - ежедневно`,
  `Правдинский - вт, чт, сб`,
  `Радошковичи - ежедневно`,
  `Руденск - ежедневно`,
  `Свирь - ср, пт, вскр`,
  `Свислочь - вт, чт, сб`,
  `Слуцк - ежедневно`,
  `Смиловичи - ежедневно`,
  `Смолевичи - ежедневно`,
  `Солигорск - ежедневно`,
  `Сопоцкин - ежедневно`,
  `Старобин - ежедневно`,
  `Старые Дороги - вт, чт, сб`,
  `Столбцы - ежедневно`,
  `Узда - вт, чт, сб`,
  `Уречье - вт, чт, сб`,
  `Фаниполь - ежедневно`,
  `Червень - ежедневно`,
];

const DeliveryThroughoutBelarus = () => {
  return (
    <div className={"information"}>
      <Title str={"Доставка по Беларуси"} />
      <div className={"product_info"}>
        <div
          style={{
            padding: "0 15px 40px",
            fontFamily: "PT Sans, arial, sans-serif",
          }}
        >
          <MenuBar />
        </div>
        <div className={"product_info_description"}>
          <div className={"zx-6"}>
            <h1 className={"title"}>Доставка по Беларуси</h1>
            <article className={"bigger"}>
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-12 order-lg1 order-1">
                  <p>
                    <span className={"city-list-1"}>
                      <strong>
                        Брест, Витебск, Гомель, Гродно, Минск, Могилев
                      </strong>{" "}
                      - ежедневно
                    </span>
                  </p>

                  <p style={{ float: "left", paddingLeft: "2px" }}>
                    <span className={"city-list-1"}>
                      <strong>Брестская область</strong>
                      <br />
                      {brest_cities.map((city) => (
                        <>
                          {city}
                          <br />
                        </>
                      ))}
                    </span>
                  </p>
                  <p style={{ float: "left", paddingLeft: "2px" }}>
                    <span className={"city-list-1"}>
                      <strong>Витебская область</strong>
                      <br />
                      {vitebsk_cities.map((city) => (
                        <>
                          {city}
                          <br />
                        </>
                      ))}
                    </span>
                  </p>
                </div>
                <div className="col-lg-4 col-sm-6 col-12  order-lg2 order-2">
                  <p style={{ float: "left", paddingLeft: "2px" }}>
                    <span className={"city-list-1"}>
                      <strong>Гомельская область</strong>
                      <br />
                      {gomel_cities.map((city) => (
                        <>
                          {city}
                          <br />
                        </>
                      ))}
                    </span>
                  </p>
                  <p style={{ float: "left", paddingLeft: "2px" }}>
                    <span className={"city-list-1"}>
                      <strong>Гродненская область</strong>
                      <br />
                      {grodno_cities.map((city) => (
                        <>
                          {city}
                          <br />
                        </>
                      ))}
                    </span>
                  </p>
                </div>
                <div className="col-lg-4 col-sm-6 col-12  order-lg3 order-3">
                  <p style={{ float: "left", paddingLeft: "2px" }}>
                    <span className={"city-list-1"}>
                      <strong>Минская область</strong>
                      <br />
                      {minsk_cities.map((city) => (
                        <>
                          {city}
                          <br />
                        </>
                      ))}
                    </span>
                  </p>
                  <p style={{ float: "left", paddingLeft: "2px" }}>
                    <span className={"city-list-1"}>
                      <strong>Могилёвская область</strong>
                      <br />
                      {mogilev_cities.map((city) => (
                        <>
                          {city}
                          <br />
                        </>
                      ))}
                    </span>
                  </p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryThroughoutBelarus;
