import React from "react";

const ClientsInfo = () => {
  return (
    <div className="col-lg-6 col-md-6 col-12 col col-description pb-40">
      <div className="section-name _h3 uppercase bold mb-20">
        <img
          src="https://marselshoes.by/i/attention.png"
          alt=""
          style={{ verticalAlign: "middle", marginTop: "-5px" }}
        />
        {` ВАЖНАЯ ИНФОРМАЦИЯ ДЛЯ КЛИЕНТОВ`}
      </div>
      <article className="bigger" data-cart-calculation-delivery-text="">
        <h2 className={"u-h2"}>&nbsp;</h2>
        <h2 className={"u-h2"}>
          <strong className={"h2-strong"}>
            <span className={"h2-strong"} style={{ color: "#808080" }}>
              БЕСПЛАТНАЯ ДОСТАВКА ПО БЕЛАРУСИ
            </span>
            &nbsp;
          </strong>
        </h2>
        <p className={"zx-1"}>
          <span className={"h2-strong"} style={{ color: "#000000" }}>
            По Беларуси у нас действует бесплатная доставка курьером с
            примеркой.
          </span>
        </p>
        <p className={"zx-1"}>
          <span className={"h2-strong"} style={{ color: "#000000" }}>
            Доставка от одного до трех дней, в зависимости от населенного
            пункта.
          </span>
        </p>
        <p className={"zx-1"}>
          <span className={"h2-strong"} style={{ color: "#000000" }}>
            При оформлении заказа до 18:00, доставка возможна на следующий день.
          </span>
        </p>
        <p className={"zx-1"}>
          <span className={"h2-strong"} style={{ color: "#000000" }}>
            Дни доставки в ваш город можно посмотреть
          </span>
          &nbsp;
          <a
            className={"h2-strong"}
            style={{
              position: "relative",
              color: "black",
              transition: "all 0.2s ease 0s",
            }}
            href="/delivery-throughout-belarus"
          >
            здесь
          </a>
        </p>
        <p className={"zx-1"}>
          <span className={"h2-strong"} style={{ color: "#000000" }}>
            Если вы не смогли найти в списке ваш населенный пункт, обращайтесь
            по номеру телефонa: +375(29)673 73 96, +375(29)673 73 15
          </span>
        </p>
        <p className={"zx-1"}>
          <span style={{ color: "#000000" }}>
            Обращаем ваше внимание, что в случае небольшого процента выкупа
            товара (меньше 75%) , может взиматься оплата за курьерские
            услуги.&nbsp;
          </span>
        </p>
        <p className={"zx-1"}>&nbsp;</p>
        <p className={"zx-1"}>&nbsp;</p>
        <h3 className={"u-h3"}>
          <span className={"h2-strong"} style={{ color: "#808080" }}>
            <strong className={"h2-strong"}>
              СКОЛЬКО ПАР ОБУВИ ВЫ МОЖЕТЕ ЗАКАЗАТЬ?
            </strong>
          </span>
        </h3>
        <p className={"zx-1"}>
          <span className={"h2-strong"} style={{ color: "#000000" }}>
            Мы доставляем до двух пар либо размеров на&nbsp;выбор.
          </span>
        </p>
        <p className={"zx-1"}>&nbsp;</p>
        <p className={"zx-1"}>&nbsp;</p>
        <h3 className={"u-h3"}>
          <span className={"h2-strong"} style={{ color: "#808080" }}>
            <strong className={"h2-strong"}>ПРИМЕРКА ПЕРЕД ПОКУПКОЙ</strong>
          </span>
        </h3>
        <p className={"zx-1"}>
          <span className={"h2-strong"} style={{ color: "#000000" }}>
            При курьере вы примеряете заказанную вами пару. Если модель вас
          </span>
          <br className={"h2-strong"} />
          <span className={"h2-strong"} style={{ color: "#000000" }}>
            устраивает и подошла по размеру - оплачиваете заказ (&nbsp;ОПЛАТА
            ПРОИЗВОДИТСЯ ТОЛЬКО НАЛИЧНЫМИ!).
          </span>
        </p>
        <p className={"zx-1"}>
          <span className={"h2-strong"} style={{ color: "#000000" }}>
            Если пара не подошла вам по размеру - возвращаете ее курьеру
            (ОПЛАЧИВАТЬ В ЭТОМ СЛУЧАЕ НИЧЕГО НЕ НАДО).
          </span>
        </p>
        <p className={"zx-1"}>&nbsp;</p>
        <p className={"zx-1"}>&nbsp;</p>
        <h3 className={"u-h3"}>
          <span className={"h3-span"}>
            <strong className={"h2-strong"}>ОПЛАТА</strong>
          </span>
        </h3>
        <p className={"zx-1"}>
          <span className={"h2-strong"} style={{ color: "#000000" }}>
            По доставке оплата производится только наличными курьеру после
            примерки.
          </span>
        </p>
        <p className={"zx-1"}>&nbsp;</p>
        <p className={"zx-1"}>&nbsp;</p>
        <p>&nbsp;</p>
      </article>
      <article className="bigger-test">
        <h3
          style={{
            fontFamily: "PT Sans,Helvetica,Roboto,Arial,sans-serif !important",
          }}
        >
          <b>
            ПОСЛЕ ОФОРМЛЕНИЯ ЗАКАЗА, В РАБОЧЕЕ ВРЕМЯ ( С 10.00- 20.00 без
            выходных) С ВАМИ СВЯЖЕТСЯ НАШ МЕНЕДЖЕР.
          </b>
        </h3>
        <br />
        <h4
          style={{
            fontSize: "16px",
            fontFamily: "PT Sans,Helvetica,Roboto,Arial,sans-serif; !important",
          }}
        >
          Остались вопросы? Позвоните нам{" "}
          <a href="tel:+375296737396">+375296737396</a> или написать в
          <a href="https://api.whatsapp.com/send/?phone=375296737396">
            whatsapp/viber
          </a>
        </h4>
      </article>
    </div>
  );
};

export default ClientsInfo;
