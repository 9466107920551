import React from "react";
import { useSelector } from "react-redux";
import CatalogItem from "../catalog/catalogItem";

const SelloutList = () => {
  const saleProducts = useSelector((state) => state.products.saleProducts);
  return (
    <div>
      <div className={`row`}>
        {saleProducts.map((product) => (
          <CatalogItem
            product={product.product}
            isCatalog={false}
            isHome={false}
          />
        ))}
      </div>
    </div>
  );
};

export default SelloutList;
