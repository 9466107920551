import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Breadcrumbs = () => {
  const fullUri = useLocation();
  let product = useSelector((state) => state.products.product);
  const strUri = decodeURIComponent(fullUri.pathname);
  const uriItems = strUri.split("/");

  const breadItems = [];
  if (uriItems.length > 1) {
    switch (uriItems[1]) {
      case "about-us": {
        breadItems.push({ id: 2, name: "О нас", href: "/about-us" });
        break;
      }
      case "certificate": {
        breadItems.push({
          id: 2,
          name: "Декларации о соответствии",
          href: "/certificate",
        });
        break;
      }
      case "confidential": {
        breadItems.push({
          id: 2,
          name: "Политика конфиденциальности",
          href: "/confidential",
        });
        break;
      }
      case "delivery": {
        breadItems.push({
          id: 2,
          name: "Доставка и оплата",
          href: "/delivery",
        });
        break;
      }
      case "delivery-throughout-belarus": {
        breadItems.push({
          id: 2,
          name: "Доставка по Беларуси",
          href: "/delivery-throughout-belarus",
        });
        break;
      }
      case "offer": {
        breadItems.push({ id: 2, name: "Оферта", href: "/offer" });
        break;
      }
      case "returns": {
        breadItems.push({ id: 2, name: "Обмен и возврат", href: "/returns" });
        break;
      }
      case "secure-pay": {
        breadItems.push({
          id: 2,
          name: "Безопасность и оплата",
          href: "/secure-pay",
        });
        break;
      }
      case "thanks-for-ordering": {
        breadItems.push({
          id: 2,
          name: "Благодарим за заказ",
          href: "/thanks-for-ordering",
        });
        break;
      }
      case "cart": {
        breadItems.push({ id: 2, name: "Корзина", href: "/cart" });
        break;
      }
      case "catalog": {
        breadItems.push({ id: 2, name: "Каталог", href: "/catalog" });
        break;
      }
      case "contact": {
        breadItems.push({ id: 2, name: "Контакты", href: "/contact" });
        break;
      }
      case "favorites": {
        breadItems.push({ id: 2, name: "Избранное", href: "/favorites" });
        break;
      }
      case "novelty": {
        breadItems.push({ id: 2, name: "Новинки", href: "/novelty" });
        break;
      }
      case "search": {
        breadItems.push({ id: 2, name: "Поиск", href: "/search" });
        break;
      }
      case "sellout": {
        breadItems.push({ id: 2, name: "Распродажа", href: "/sellout" });
        break;
      }
      case "":
      default: {
        break;
      }
    }
    if (uriItems.length > 2) {
      breadItems.push({
        id: 3,
        name: capitalizeFirstLetter(uriItems[2]),
        href: `/${uriItems[1]}/${uriItems[2]}`,
      });
      if (uriItems.length > 3) {
        breadItems.push({
          id: 4,
          name: product.name,
          href: `/${uriItems[1]}/${uriItems[2]}/${uriItems[3]}/${uriItems[4]}`,
        });
      }
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <>
      {breadItems.length !== 0 && (
        <div className={"s-breadcrumbs"} style={{ top: "43px", width: "100%" }}>
          <div className={"container"} style={{ marginTop: "0px" }}>
            <div
              className="w-breadcrumbs"
              itemScope=""
              itemType="https://schema.org/BreadcrumbList"
            >
              <span
                itemProp="itemListElement"
                itemScope=""
                itemType="https://schema.org/ListItem"
              >
                <a href="/" itemProp="item">
                  <span itemProp="name">Главная</span>
                </a>
                <meta itemProp="position" content="1" />
              </span>
              {breadItems.map((item) => (
                <span
                  className="current"
                  itemProp="itemListElement"
                  itemScope=""
                  itemType="https://schema.org/ListItem"
                  style={{ marginLeft: "5px" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="10"
                    fill="currentColor"
                    className="w-breadcrumbs__icon"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                    ></path>
                  </svg>
                  <a
                    href={item.href}
                    itemProp="item"
                    style={{ marginLeft: "5px" }}
                  >
                    <span itemProp="name">{item.name}</span>
                  </a>
                  <meta itemProp="position" content={item.id} />
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Breadcrumbs;
